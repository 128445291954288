import React from 'react'
import styled from 'styled-components'
import Carousel from 're-carousel'
import { useDispatch } from 'react-redux'
import { CarouselDots } from './carouselDots'
import { Grid } from '@material-ui/core'
import imageNotFound from '../../../../assets/images/image-not-found.jpg'
import imageNotFoundBis from '../../../../assets/images/image-not-found-bis.jpg'
import noImage from '../../../../assets/images/no-image.jpg'
import noImageBis from '../../../../assets/images/no-image-bis.jpg'
import { STAGING_IMAGE_PATH_PREFIX } from '../../../../const'
import { switchAltImageVisibleFlag } from '../../page.redux'
import { VideoPlayer } from '../../../reusable/videoPlayer'
import { PANEL_SIZE } from '../../page.type'

/**
 * Preview banner as guest view or not
 *
 * @param {Object} params
 * @param {string} params.panelId Panel ID
 * @param {string} params.heading Heading
 * @param {string} params.subheading Subheading
 * @param {string} params.guestHeader Guest header
 * @param {string} params.guestSubheader Guest subheader
 * @param {boolean} isGuestView is guest view tag
 * @param {Object} classes CSS classes
 */
export const bannerPreview = ({ panelId, heading, subheading, guestHeader, guestSubheader }, isGuestView, classes) => (
  <div key={panelId} className={classes.banner}>
    <p>{isGuestView ? guestHeader : heading}</p>
    <p>{isGuestView ? guestSubheader : subheading}</p>
  </div>
)

/**
 * Preview carousel
 *
 * @param {Object} params
 * @param {Object[]} params.panels Array of panels
 * @param {string} params.panelId Panel ID
 * @param {Object} classes CSS classes
 * @param {string} localePreview Locale preview
 */
export const carouselPreview = ({ panels, panelId }, classes, localePreview) => {
  const dispatch = useDispatch
  return (<>
  <div key={panelId} className={classes.carousel}>
    <img
      className={classes.carouselPlaceholder}
      onError={e => {
        e.target.onerror = null
        e.target.src = imageNotFound
      }}
      src={`${STAGING_IMAGE_PATH_PREFIX}${panels[0].imageUrl}` || noImage}
      alt="carousel placeholder"
    />
    <Carousel widgets={[CarouselDots]}>
      {panels.map(
        ({ background, backgroundColor, imageUrl, hasEmbeddedText, panelText, textPosition, textStyle, videoId }, index) => {
            if(videoId){
              return (
                <VideoPlayer
                  videoId={videoId}
                  onFailure={() =>
                    // Hide the video player and display the alternative image if an error occurs
                    dispatch(
                      switchAltImageVisibleFlag({
                        locale: localePreview,
                        panelIndex: index,
                        isAltImageVisible: true,
                      })
                    )
                  }
                />
              )
            }else{
              return(
                <div key={index} className={classes.carouselItem}>
                  {background === 'imageUrl' ? (
                    <img
                      className={classes.carouselPanel}
                      onError={e => {
                        e.target.onerror = null
                        e.target.src = imageNotFound
                      }}
                      src={`${STAGING_IMAGE_PATH_PREFIX}${imageUrl}` || noImage}
                      alt={`carousel item ${index}`}
                    />
                  ) : (
                    <BackgroundPanel backgroundColor={backgroundColor} />
                  )}
                </div>
              )
            }
          }
      )}
    </Carousel>
  </div>
  </>)
}
/**
 * Preview Full panel properties
 *
 * @param {Object} params
 * @param {string} params.panelId Panel ID
 * @param {string} params.background Background type
 * @param {string} params.backgroundColor Background color
 * @param {string} params.imageUrl Image URL
 * @param {boolean} params.hasEmbeddedText Has embedded text flag
 * @param {string} params.panelText Panel text
 * @param {string} params.textPosition Text position
 * @param {string} params.textStyle Text style
 * @param {Object} classes CSS classes
 */
export const fullPanelPreview = (
  { panelId, background, backgroundColor, imageUrl, hasEmbeddedText, panelText, textPosition, textStyle, panelSize },
  classes
) => {
  const getSize = (size) => {
    switch (size) {
      case PANEL_SIZE.LARGE:
        return '100%'
      case PANEL_SIZE.MEDIUM:
        return '48%'
      case PANEL_SIZE.SMALL:
        return '25%'
      case PANEL_SIZE.EXTRA_SMALL:
          return '6%'
      default:
        return '50%'
    }}
  return (
  <div key={panelId} className={classes.fullPanel} style={{height: getSize(panelSize)}} >
    {background === 'imageUrl' ? (
      <img
        onError={e => {
          e.target.onerror = null
          e.target.src = imageNotFound
        }}
        className={classes.fullPanelImg}
        src={`${STAGING_IMAGE_PATH_PREFIX}${imageUrl}` || noImage}
        alt="Full panel"
      />
    ) : (
      <BackgroundPanel backgroundColor={backgroundColor} />
    )}
  </div>
)}

/**
 * Preview half panel properties
 *
 * @param {Object} params
 * @param {Object} params.leftPanel Left panel values
 * @param {Object} params.rightPanel Right panel values
 * @param {string} params.panelId Panel ID
 * @param {Object} classes CSS classes
 */
export const halfPanelPreview = ({ leftPanel, rightPanel, panelId }, classes) => (
  <div key={panelId} className={classes.fullPanel}>
    <div className={classes.halfPanelItem}>
      {leftPanel.background === 'imageUrl' ? (
        <img
          onError={e => {
            e.target.onerror = null
            e.target.src = imageNotFoundBis
          }}
          src={`${STAGING_IMAGE_PATH_PREFIX}${leftPanel.imageUrl}` || noImageBis}
          alt="Left panel"
          style={{ marginLeft: '1px' }}
        />
      ) : (
        <BackgroundPanel style={{ marginLeft: '1px' }} backgroundColor={leftPanel.backgroundColor} />
      )}
      {leftPanel.hasEmbeddedText && (
        <EmbeddedText textPosition={leftPanel.textPosition} textStyle={leftPanel.textStyle}>
          {leftPanel.panelText}
        </EmbeddedText>
      )}
    </div>
    <div className={classes.halfPanelItem}>
      {rightPanel.background === 'imageUrl' ? (
        <img
          onError={e => {
            e.target.onerror = null
            e.target.src = imageNotFoundBis
          }}
          src={`${STAGING_IMAGE_PATH_PREFIX}${rightPanel.imageUrl}` || noImageBis}
          alt="Right panel"
        />
      ) : (
        <BackgroundPanel backgroundColor={rightPanel.backgroundColor} />
      )}
      {rightPanel.hasEmbeddedText && (
        <EmbeddedText textPosition={rightPanel.textPosition} textStyle={rightPanel.textStyle}>
          {rightPanel.panelText}
        </EmbeddedText>
      )}
    </div>
  </div>
)

/**
 * Preview video panel properties
 *
 * @param {Object} panel Panel properties
 * @param {Object} classes CSS classes
 * @param {string} localePreview Locale preview
 * @param {number} index Index
 */
export const VideoPreview = (panel, classes, localePreview, index) => {
  const dispatch = useDispatch()

  return (
    <div key={panel.panelId}>
      {!panel.isAltImageVisible && (
        <div className={classes.fullPanel}>
          {(panel.hasHeader || panel.hasSubHeader || panel.hasButton) && (
            <EmbeddedContentVideo>
              {panel.hasHeader && (
                <EmbeddedHeaderVideo
                  headerType="header"
                  textPosition={panel.headerPosition}
                  fontColor={panel.headerFontColor}
                  fontSize={panel.headerFontSize}
                  fontWeight={panel.headerFontWeight}
                  fontFamily={panel.headerFontFamily}
                >
                  {panel.headerText}
                </EmbeddedHeaderVideo>
              )}
              {panel.hasSubHeader && (
                <EmbeddedHeaderVideo
                  headerType="subHeader"
                  textPosition={panel.subHeaderPosition}
                  fontColor={panel.subHeaderFontColor}
                  fontSize={panel.subHeaderFontSize}
                  fontWeight={panel.subHeaderFontWeight}
                  fontFamily={panel.subHeaderFontFamily}
                >
                  {panel.subHeaderText}
                </EmbeddedHeaderVideo>
              )}
              {panel.hasButton && (
                <EmbeddedButtonVideo
                  backgroundColor={panel.buttonBackgroundColor}
                  fontFamily={panel.buttonFontFamily}
                  fontColor={panel.buttonFontColor}
                  fontSize={panel.buttonFontSize}
                  borderRadius={panel.buttonBorderRadius}
                  verticalPadding={panel.buttonVerticalPadding}
                  horizontalPadding={panel.buttonHorizontalPadding}
                  position={panel.buttonPosition}
                >
                  {panel.buttonText}
                </EmbeddedButtonVideo>
              )}
            </EmbeddedContentVideo>
          )}
          {panel.hasTint && (
            <TintOverlayVideo colorOverlay={panel.tintColorOverlay} colorOpacity={panel.tintColorOpacity} />
          )}
          <VideoPlayer
            videoId={panel.videoId}
            onFailure={() =>
              // Hide the video player and display the alternative image if an error occurs
              dispatch(
                switchAltImageVisibleFlag({
                  locale: localePreview,
                  panelIndex: index,
                  isAltImageVisible: true,
                })
              )
            }
          />
        </div>
      )}
      {panel.isAltImageVisible && (
        <img
          onError={e => {
            e.target.onerror = null
            e.target.src = imageNotFound
          }}
          className={classes.fullPanelImg}
          src={`${STAGING_IMAGE_PATH_PREFIX}${panel.altImageUrl}` || noImage}
          alt="Alternative full panel"
        />
      )}
    </div>
  )
}

export const footerPreview = ({ footerItems }, classes) => (
  <Grid className={classes.footer} container direction="row" alignItems="center" justify="center">
    {footerItems.map(({ footerItemId, label }) => (
      <Grid item xs={12 / footerItems.length} key={footerItemId}>
        {label}
      </Grid>
    ))}
  </Grid>
)

export const categoryPreview = (panel, classes) => <div className={classes.categoryItem}>{panel.categoryName}</div>

export const socialPreview = (panel, classes) => (
  <Grid className={classes.footer} container direction="row" alignItems="center" justify="center">
    <div>Place Holder of Social Media</div>
  </Grid>
)

export const EmbeddedText = styled.span`
  width: 90%;
  font-size: 20px;
  text-align: ${({ textPosition }) => textPosition};
  position: absolute;
  top: 50%;
  left: 5%;
  color: ${({ textStyle }) => (textStyle === 'light' ? 'white' : 'black')};
  transform: translate(0%, -50%);
`

export const EmbeddedContentVideo = styled.div`
  position: absolute;
  width: 90%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
`

export const EmbeddedHeaderVideo = styled.div`
  width: 100%;
  margin: 0px auto 10px;
  text-align: ${({ textPosition }) => textPosition};
  font-family: ${({ fontFamily }) => fontFamily};
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  line-height: ${({ fontSize }) => `${fontSize}px`};
  top: ${({ headerType }) => (headerType === 'header' ? '35%' : '45%')};
  color: ${({ fontColor }) => fontColor};
`

export const EmbeddedButtonVideo = styled.div`
  text-align: center;
  width: fit-content;
  margin: 15px auto 0;
  float: ${({ position }) => (position === 'left' || position === 'right') && position};
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  color: ${({ fontColor }) => fontColor};
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: ${({ horizontalPadding, verticalPadding }) => `${verticalPadding}px ${horizontalPadding}px`};
  border-radius: ${({ borderRadius }) => `${borderRadius}px`};
`

export const TintOverlayVideo = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ colorOverlay }) => colorOverlay};
  opacity: ${({ colorOpacity }) => colorOpacity};
  z-index: 500;
  position: absolute;
`

export const BackgroundPanel = styled.div`
  min-height: 148px;
  height: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};
`
