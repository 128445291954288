import React from 'react'
import { reduxForm, FieldArray, FormSection, SubmissionError } from 'redux-form'
import { connect, useSelector, useDispatch } from 'react-redux'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { changeTab, onSavePage } from '../page.redux'
import { GeneralInfos } from './generalInfos'
import { PanelItems } from './panels/panelItems'
import { LOCALES } from '../page.type'
import { FORM_NAMES } from '../../modules.constants'
import {
  Box,
  AppBar,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
  Avatar,
} from '@material-ui/core'
import { green, orange } from '@material-ui/core/colors'
import { openModal } from '../../modal/modal.redux'
import { validatePageForm } from '../page.logic'

const useStyles = makeStyles(() => ({
  box: {
    paddingBottom: '88px',
  },
  tab: {
    textTransform: 'none',
  },
}))

const renderValidationErrors = ({ nbErrors, ...locales }) => {
  const OrangeAvatar = withStyles({
    root: { backgroundColor: orange[900] },
  })(Avatar)

  const GreenAvatar = withStyles({
    root: { backgroundColor: green[700] },
  })(Avatar)

  return (
    <>
      <Typography color="textSecondary">
        {nbErrors} validation error{nbErrors > 1 ? 's have' : ' has'} been found.
      </Typography>
      <List>
        {Object.keys(locales).map(locale => (
          <ListItem disableGutters key={locale}>
            <ListItemAvatar>
              {locales[locale].nbLocaleErrors > 0 ? (
                <OrangeAvatar>{locales[locale].nbLocaleErrors}</OrangeAvatar>
              ) : (
                <GreenAvatar>0</GreenAvatar>
              )}
            </ListItemAvatar>
            <ListItemText primary={'PANELS ' + locale} />
          </ListItem>
        ))}
      </List>
    </>
  )
}

const Form = ({ handleSubmit }) => {
  const classes = useStyles()
  const { selectedTab } = useSelector(state => state.page)
  const dispatch = useDispatch()

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <AppBar position="static" color="default">
        <Tabs
          value={selectedTab}
          indicatorColor="secondary"
          textColor="secondary"
          onChange={(_, value) => dispatch(changeTab(value))}
        >
          <Tab className={classes.tab} label="INFOS" />
          <Tab className={classes.tab} label="PANELS EN_CA" />
          <Tab className={classes.tab} label="PANELS EN_US" />
          <Tab className={classes.tab} label="PANELS FR_CA" />
        </Tabs>
      </AppBar>
      <Box p={3} className={classes.box}>
        {selectedTab === 0 ? (
          <GeneralInfos />
        ) : selectedTab === 1 ? (
          <FormSection name={LOCALES.EN_CA}>
            <FieldArray name="panels" locale={LOCALES.EN_CA} component={PanelItems} />
          </FormSection>
        ) : selectedTab === 2 ? (
          <FormSection name={LOCALES.EN_US}>
            <FieldArray name="panels" locale={LOCALES.EN_US} component={PanelItems} />
          </FormSection>
        ) : (
          <FormSection name={LOCALES.FR_CA}>
            <FieldArray name="panels" locale={LOCALES.FR_CA} component={PanelItems} />
          </FormSection>
        )}
      </Box>
    </form>
  )
}

const PageForm = reduxForm({
  form: FORM_NAMES.PAGE,
  enableReinitialize: true,
  destroyOnUnmount: false,
  onSubmit: (values, dispatch) => {
    const { errors, nbErrors } = validatePageForm(values, dispatch)
    if (nbErrors > 0) throw new SubmissionError({ ...errors, nbErrors })
  },
  onSubmitSuccess: (_, dispatch) => dispatch(onSavePage()),
  onSubmitFail: (errors, dispatch) =>
    dispatch(
      openModal({
        textHeader: 'Page Validation',
        additionalContent: renderValidationErrors(errors),
        autoClose: true,
      })
    ),
})(Form)

export default connect(({ page: { pageInfos } }) => ({
  initialValues: pageInfos.cata({
    JUST: value => value,
    NOTHING: () => {},
  }),
}))(PageForm)
