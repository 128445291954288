import React from 'react'
import { makeStyles, Container } from '@material-ui/core'
import { STAGING_IMAGE_PATH_PREFIX } from '../../../../const'
import imageNotFoundBis from '../../../../assets/images/image-not-found-bis.jpg'

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: '600px',
    padding: '0',
  },
  layout1: {
    height: '400px',
    display: 'flex',
  },
  layout2: {
    height: '200px',
    display: 'flex',
  },
  galleryCellOneSmallImage: {
    display: 'flex',
    flex: '1 1 0%',
  },
  galleryCellOneBigImage: {
    display: 'flex',
    flex: '2 1 0%',
  },
  galleryCellTwoSmallImages: {
    display: 'flex',
    flex: '1 1 0%',
    flexDirection: 'column',
  },
  galleryItem: {
    height: '100%',
    width: '100%',
    display: 'flex',
    padding: '1.5px',
    boxSizing: 'border-box',
    overflow: 'hidden',
  },
  galleryImage: {
    width: '100%',
    objectFit: 'cover',
  },
}))

const previewImage = (image, classes) =>
  image && (
    <img
      className={classes.galleryImage}
      src={`${STAGING_IMAGE_PATH_PREFIX}${image.imageUrl}`}
      onError={e => {
        e.target.onerror = null
        e.target.src = imageNotFoundBis
      }}
      alt="Syte inspiration"
    />
  )

export const PreviewGalleryImages = ({ previewValues }) => {
  const classes = useStyles()

  return (
    <Container className={classes.container}>
      {previewValues.map(({ layoutType, chunkImages }, i) =>
        layoutType === 0 ? (
          <div key={i} className={classes.layout1}>
            <div className={classes.galleryCellOneBigImage}>
              <div className={classes.galleryItem}>{previewImage(chunkImages[0], classes)}</div>
            </div>
            <div className={classes.galleryCellTwoSmallImages}>
              <div className={classes.galleryItem}>{previewImage(chunkImages[1], classes)}</div>
              <div className={classes.galleryItem}>{previewImage(chunkImages[2], classes)}</div>
            </div>
          </div>
        ) : layoutType === 1 ? (
          <div key={i} className={classes.layout2}>
            {chunkImages.map((syteImage, j) => (
              <div key={j} className={classes.galleryCellOneSmallImage}>
                <div className={classes.galleryItem}>{previewImage(syteImage, classes)}</div>
              </div>
            ))}
          </div>
        ) : (
          <div key={i} className={classes.layout1}>
            <div className={classes.galleryCellTwoSmallImages}>
              <div className={classes.galleryItem}>{previewImage(chunkImages[0], classes)}</div>
              <div className={classes.galleryItem}>{previewImage(chunkImages[1], classes)}</div>
            </div>
            <div className={classes.galleryCellOneBigImage}>
              <div className={classes.galleryItem}>{previewImage(chunkImages[2], classes)}</div>
            </div>
          </div>
        )
      )}
    </Container>
  )
}
