import React from 'react'
import { Field } from 'redux-form'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Button, InputAdornment } from '@material-ui/core'
import Input from './input'
import ImageIcon from '@material-ui/icons/Image'
import { openModal } from '../modal/modal.redux'
import {
  getStagingImagePathRegex,
  getProductionImagePathRegex,
  isStagingOrProductionImagePath,
} from '../../utils/utils'

const useStyles = makeStyles(() => ({
  subGridItem: { paddingRight: '16px' },
  previewButton: { height: '100%' },
}))

const normalizeImageUrl = value =>
  isStagingOrProductionImagePath(value)
    ? value.replace(getStagingImagePathRegex, '').replace(getProductionImagePathRegex, '')
    : value

/**
 * Render image url field with customizable label, field name, and preview button
 *
 * @param {Object} params
 * @param {string} params.label Label
 * @param {string} params.fieldName Field name
 * @param {boolean} params.imageUrlValue Image Url value
 */
const ImageUrlField = ({ label, fieldName, imageUrlValue }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  return (
    <>
      <Grid className={classes.subGridItem} item xs={9}>
        <Field
          component={Input}
          label={label}
          name={fieldName}
          normalize={normalizeImageUrl}
          InputProps={{
            startAdornment: <InputAdornment position="start">./default/</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <Button
          fullWidth
          size="large"
          onClick={() =>
            dispatch(
              openModal({
                textHeader: 'Image preview',
                imagePreview: imageUrlValue,
                maxWidth: 'md',
                autoClose: true,
              })
            )
          }
          variant="contained"
          color="primary"
          disabled={!imageUrlValue}
          className={classes.previewButton}
          startIcon={<ImageIcon />}
        >
          Preview
        </Button>
      </Grid>
    </>
  )
}

export default ImageUrlField
