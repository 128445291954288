import { createAction, handleActions } from 'redux-actions'

export const tryLogin = createAction('AUTH_TRY_LOGIN')
export const loginSuccess = createAction('AUTH_LOGIN_SUCCESS')
export const logout = createAction('AUTH_LOGOUT')
export const refreshToken = createAction('AUTH_REFRESH_TOKEN')
export const updateToken = createAction('AUTH_UPDATE_TOKEN')
export const setRedirectPath = createAction('AUTH_SET_REDIRECT_PATH')

const INITIAL_STATE = {
  authenticated: false,
  userInfo: null,
  token: null,
  redirectPath: null,
}

export const authReducer = handleActions(
  {
    [loginSuccess]: (state, { payload: { userInfo, token } }) => ({
      ...state,
      authenticated: true,
      userInfo,
      token,
    }),
    [logout]: state => ({
      ...state,
      ...INITIAL_STATE,
    }),
    [updateToken]: (state, { payload: token }) => ({
      ...state,
      token,
    }),
    [setRedirectPath]: (state, { payload: path }) => ({
      ...state,
      redirectPath: path,
    }),
  },
  INITIAL_STATE
)
