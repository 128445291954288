import React from 'react'
import _ from 'lodash'
import { getFormValues } from 'redux-form'
import { useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import { EmbeddedText, GeneralInfos } from './reusable'
import { FORM_NAMES } from '../../../modules.constants'

const FullPanelSubForm = ({ locale, panel , panelType}) => {
  const pageFormValues = useSelector(state => getFormValues(FORM_NAMES.PAGE)(state))
  const panelValues = _.get(pageFormValues, `${locale}.${panel}`)

  return (
    <Grid container direction="row">
      <Grid xs={6} item>
        <GeneralInfos
          panel={panel}
          locale={locale}
          actionType={panelValues.actionType}
          background={panelValues.background}
          imageUrl={panelValues.imageUrl}
          panelType={panelType}
        />
      </Grid>
      <Grid xs={6} item>
        {panelValues.hasEmbeddedText && <EmbeddedText panel={panel} />}
      </Grid>
    </Grid>
  )
}

export default FullPanelSubForm
