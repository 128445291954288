import React from 'react'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { getFormValues } from 'redux-form'
import { Grid, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { FORM_NAMES } from '../../../modules.constants'
import { EmbeddedText, GeneralInfos } from './reusable'

const useStyles = makeStyles(() => ({
  gridItemWithPadding: {
    padding: '16px 12px 0 12px',
  },
  typography: {
    fontWeight: 'bold',
  },
}))

const HalfPanelSubForm = ({ locale, panel }) => {
  const classes = useStyles()
  const pageFormValues = useSelector(state => getFormValues(FORM_NAMES.PAGE)(state))
  const panelValues = _.get(pageFormValues, `${locale}.${panel}`)

  return (
    <Grid container direction="row">
      <Grid xs={6} item>
        <Grid className={classes.gridItemWithPadding} item>
          <Typography className={classes.typography} variant="body1" color="primary">
            Left panel
          </Typography>
        </Grid>
        <GeneralInfos
          panel={`${panel}.leftPanel`}
          actionType={panelValues.leftPanel.actionType}
          background={panelValues.leftPanel.background}
          imageUrl={panelValues.leftPanel.imageUrl}
        />
        {panelValues.leftPanel.hasEmbeddedText && <EmbeddedText panel={`${panel}.leftPanel`} />}
      </Grid>
      <Grid xs={6} item>
        <Grid className={classes.gridItemWithPadding} item>
          <Typography className={classes.typography} variant="body1" color="primary">
            Right panel
          </Typography>
        </Grid>
        <GeneralInfos
          panel={`${panel}.rightPanel`}
          actionType={panelValues.rightPanel.actionType}
          background={panelValues.rightPanel.background}
          imageUrl={panelValues.rightPanel.imageUrl}
        />
        {panelValues.rightPanel.hasEmbeddedText && <EmbeddedText panel={`${panel}.rightPanel`} />}
      </Grid>
    </Grid>
  )
}

export default HalfPanelSubForm
