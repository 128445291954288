/**
 * This number dictates how long can a person stay authenticated, in miliseconds. When
 * the time limit expires, the next time they get on the site, they will be logged out.
 *
 * As of now, the auth limit is 3 days.
 */
export const TTL = 3 * 24 * 60 * 60 * 1000

/**
 * Only emails carrying domains from this array will be able to log into the Mobile app CMS.
 */
export const ALLOWED_DOMAINS = ['dynamite.ca']

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID
export const API_URL = process.env.REACT_APP_API_URL
export const API_USER_ID = process.env.REACT_APP_API_USER_ID.trim()
export const BRIGHTCOVE_ACCOUNT_ID = process.env.REACT_APP_BRIGHTCOVE_ACCOUNT_ID
export const BRIGHTCOVE_PLAYER_ID = process.env.REACT_APP_BRIGHTCOVE_PLAYER_ID
export const BRIGHTCOVE_ASPECT_RATIO = '552:699'

export const MOBILE_APP_FONTS = [
  { key: 'Azo', value: 'Azo' },
  { key: 'ChronicleDisplay-Bold', value: 'ChronicleDisplay-Bold' },
  { key: 'ChronicleDisplay-BoldItalic', value: 'ChronicleDisplay-BoldItalic' },
  { key: 'ChronicleDisplay-Light', value: 'ChronicleDisplay-Light' },
  { key: 'EyeCatching', value: 'Eye Catching' },
  { key: 'HelveticaCondensed', value: 'Helvetica' },
  { key: 'SFUIText-Regular', value: 'SF UI Text' },
  { key: 'SFProDisplay-Semibold', value: 'SF Pro Display' },
  { key: 'Verveine', value: 'Verveine' },
]

export const USER_GUIDE_URL = 'https://docs.google.com/document/d/1nDKoOdIzwOnTZ98ZHZHmKWxvJmHNreVDyZ3lTqiXJdc'
export const STAGING_IMAGE_PATH_PREFIX =
  'https://staging-na01-dynamite.demandware.net/on/demandware.static/-/Library-Sites-global_library_ca/default/'
export const PRODUCTION_IMAGE_PATH_PREFIX =
  'https://www.garageclothing.com/on/demandware.static/-/Library-Sites-global_library_ca/default/'
