import React from 'react'
import { FieldArray, getFormValues } from 'redux-form'
import { useSelector } from 'react-redux'
import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ImageUrlField from '../../../reusable/imageUrlField'
import { FORM_NAMES } from '../../../modules.constants'
import { ArrowDownward, ArrowUpward } from '@material-ui/icons'

const useStyles = makeStyles(() => ({
  gridItemWithPadding: {
    height: '72px',
    lineHeight: '40px',
    padding: '16px 0',
  },
  galleryImageMenu: {
    display: 'flex',
    height: '56px',
    paddingTop: '4px',
  },
  inspirationGalleryTypo: {
    margin: '10px 0',
  },
  iconDiv: {
    width: '48px',
    marginLeft: '10px',
  },
}))

const GalleryImagesForm = ({ fields, galleryValues }) => {
  const classes = useStyles()

  return fields.map((galleryImage, index) => (
    <Grid key={index} container className={classes.gridItemWithPadding} justify="space-between">
      <Grid item xs={9}>
        <Grid container justify="space-between">
          <ImageUrlField
            label="Image URL"
            fieldName={`${galleryImage}.imageUrl`}
            imageUrlValue={galleryValues[index].imageUrl}
          />
        </Grid>
      </Grid>
      <Grid item className={classes.galleryImageMenu} xs={3}>
        <div className={classes.iconDiv}>
          {index !== fields.length - 1 && (
            <Tooltip title="Move down">
              <IconButton color="primary" onClick={() => fields.swap(index + 1, index)}>
                <ArrowDownward />
              </IconButton>
            </Tooltip>
          )}
        </div>

        <div className={classes.iconDiv}>
          {index !== 0 && (
            <Tooltip title="Move up">
              <IconButton color="primary" onClick={() => fields.swap(index - 1, index)}>
                <ArrowUpward />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </Grid>
    </Grid>
  ))
}

export const SyteGalleryForm = () => {
  const contentFormValues = useSelector(state => getFormValues(FORM_NAMES.CONTENT)(state))
  const classes = useStyles()

  return (
    <>
      <Grid item xs={6}>
        <Grid container justify="space-between">
          <ImageUrlField
            label="Hero Image"
            fieldName="heroImage.imageUrl"
            imageUrlValue={contentFormValues.heroImage.imageUrl}
          />
        </Grid>
      </Grid>
      <Grid item xs={10}>
        <Grid item>
          <Typography className={classes.inspirationGalleryTypo} component="p">
            INSPIRATION GALLERY IMAGES
          </Typography>
        </Grid>
        <FieldArray
          name="galleryImages"
          galleryValues={contentFormValues.galleryImages}
          component={GalleryImagesForm}
        />
      </Grid>
    </>
  )
}
