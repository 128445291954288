import React, { useEffect } from 'react'
import { submit } from 'redux-form'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { contentMount, onDeleteContent, showPreview } from '../content.redux'
import { PlayArrow, Delete, Save } from '@material-ui/icons'
import ContentForm from './contentForm'
import { BottomMenu } from '../../reusable/bottomMenu'
import { FORM_NAMES } from '../../modules.constants'
import { openModal } from '../../modal/modal.redux'
import { PreviewModal } from './previewModal'

export const Content = () => {
  const dispatch = useDispatch()
  const { contentId } = useParams()

  useEffect(() => {
    dispatch(contentMount({ contentId }))
  }, [dispatch, contentId])

  const leftItems = [
    {
      type: 'button',
      value: 'Save',
      onClickCallback: () => dispatch(submit(FORM_NAMES.CONTENT)),
      startIcon: <Save />,
    },
    {
      type: 'button',
      value: 'Delete',
      onClickCallback: () =>
        dispatch(
          openModal({
            textHeader: 'Delete page',
            textContent: 'Are you sure you want to delete this content? This action will be irreversible.',
            callback: () => dispatch(onDeleteContent()),
            autoClose: true,
          })
        ),
      startIcon: <Delete />,
    },
  ]

  const rightItems = [
    {
      type: 'button',
      value: 'Preview',
      onClickCallback: () => dispatch(showPreview()),
      startIcon: <PlayArrow />,
    },
  ]

  return (
    <>
      <ContentForm />
      <BottomMenu leftItems={leftItems} rightItems={rightItems} />
      <PreviewModal />
    </>
  )
}
