import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { homeMount } from '../home.redux'
import {
  makeStyles,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core'
import { openModal } from '../../modal/modal.redux'
import { FORM_NAMES } from '../../modules.constants'
import { Maybe } from '../../../utils/utils.types'
import { BRAND, CONTENT_TYPE, PAGE_TYPE } from '../../modules.constants'
import { AddCircle } from '@material-ui/icons'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { BottomMenu } from '../../reusable/bottomMenu'

const useStyles = makeStyles(theme => ({
  tableRow: {
    cursor: 'pointer',
    height: '62px',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  tableCell: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}))

export const Home = () => {
  const { pageList, contentList } = useSelector(({ home }) => home)
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()

  useEffect(() => {
    dispatch(homeMount())
  }, [dispatch])

  const leftItems = [
    {
      type: 'button',
      value: 'Add new page',
      onClickCallback: () =>
        dispatch(
          openModal({
            textHeader: 'Add new page',
            form: FORM_NAMES.NEW_PAGE,
            autoClose: false,
          })
        ),
      startIcon: <AddCircle />,
    },
    {
      type: 'button',
      value: 'Add new content',
      onClickCallback: () =>
        dispatch(
          openModal({
            textHeader: 'Add new content',
            form: FORM_NAMES.NEW_CONTENT,
            autoClose: false,
          })
        ),
      startIcon: <AddCircle />,
    },
  ]

  return (
    <>
      {Maybe.JUST.is(pageList) && (
        <Box p={3}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '40%' }} className={classes.tableCell}>
                    Page Type
                  </TableCell>
                  <TableCell style={{ width: '40%' }} className={classes.tableCell}>
                    Brand
                  </TableCell>
                  <TableCell style={{ width: '20%' }} className={classes.tableCell}>
                    Is Online
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pageList.value.length > 0 ? (
                  pageList.value.map(page => (
                    <TableRow
                      className={classes.tableRow}
                      onClick={() => history.push(`/page/${page.page_id}`)}
                      key={page.page_id}
                    >
                      {/* NOTE: using regex to replace all underscore characters (some values have more than one underscore) */}
                      <TableCell>{PAGE_TYPE[page.page_type].replace(/_/g, ' ')}</TableCell>
                      <TableCell>{BRAND[page.brand]}</TableCell>
                      <TableCell>{!!page.online_version_id && <CheckBoxIcon />}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell>No page found.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      {Maybe.JUST.is(contentList) && (
        <Box p={3}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '40%' }} className={classes.tableCell}>
                    Content Type
                  </TableCell>
                  <TableCell className={classes.tableCell}>Brand</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contentList.value.length > 0 ? (
                  contentList.value.map(content => (
                    <TableRow
                      className={classes.tableRow}
                      onClick={() => history.push(`/content/${content.content_id}`)}
                      key={content.content_id}
                    >
                      <TableCell>{CONTENT_TYPE[content.content_type].replace('_', ' ')}</TableCell>
                      <TableCell>{BRAND[content.brand]}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell>No content found.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      <BottomMenu leftItems={leftItems} />
    </>
  )
}
