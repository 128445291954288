import { Maybe } from '../../utils/utils.types'
import { createAction, handleActions } from 'redux-actions'

export const contentMount = createAction('CONTENT_CONTENT_MOUNT')
export const fetchContentInfos = createAction('CONTENT_FETCH_CONTENT_INFOS')
export const loadContentInfos = createAction('CONTENT_LOAD_CONTENT_INFOS')
export const onSaveContent = createAction('CONTENT_ON_SAVE_CONTENT')
export const saveContent = createAction('CONTENT_SAVE_CONTENT')
export const onDeleteContent = createAction('CONTENT_ON_DELETE_CONTENT')
export const deleteContent = createAction('CONTENT_DELETE_CONTENT')
export const showPreview = createAction('CONTENT_SHOW_PREVIEW')
export const hidePreview = createAction('CONTENT_HIDE_PREVIEW')
export const loadPreviewValues = createAction('CONTENT_LOAD_PREVIEW_VALUES')

export const INITIAL_STATE = {
  contentInfos: Maybe.NOTHING,
  contentType: null,
  currentContentId: null,
  preview: {
    isPreviewed: false,
    previewValues: [],
  },
}

export const contentReducer = handleActions(
  {
    [contentMount]: () => INITIAL_STATE,
    [loadContentInfos]: (state, { payload }) => ({
      ...state,
      contentInfos: Maybe.JUST(payload),
      contentType: payload.contentType,
      currentContentId: payload.contentId,
    }),
    [showPreview]: state => ({
      ...state,
      preview: {
        ...state.preview,
        isPreviewed: true,
      },
    }),
    [hidePreview]: state => ({
      ...state,
      preview: {
        ...state.preview,
        isPreviewed: false,
      },
    }),
    [loadPreviewValues]: (state, { payload }) => ({
      ...state,
      preview: {
        ...state.preview,
        previewValues: payload,
      },
    }),
  },
  INITIAL_STATE
)
