import { reducer as formReducer } from 'redux-form'
import { authReducer } from '../modules/auth/auth.redux'
import { pageReducer } from '../modules/page/page.redux'
import { contentReducer } from '../modules/content/content.redux'
import { homeReducer } from '../modules/home/home.redux'
import { loaderReducer } from '../modules/loader/loader.redux'
import { toastReducer } from '../modules/toast/toast.redux'
import { modalReducer } from '../modules/modal/modal.redux'

export const reducers = {
  form: formReducer,
  auth: authReducer,
  loader: loaderReducer,
  toast: toastReducer,
  modal: modalReducer,
  page: pageReducer,
  content: contentReducer,
  home: homeReducer,
}
