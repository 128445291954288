import React from 'react';
import { Field } from 'redux-form';
import { Grid } from '@material-ui/core';
import Input from '../../../reusable/input';

// NOTE: custom Grid Item Component with padding
const GridItem = ({ children }) => (
  <Grid 
    item
    style={{ padding: '16px 12px 0 12px', }} 
    xs={6} 
    container 
    direction="column"
  >
    {children}
  </Grid>
); 

/**
 * Renders category panel sub form
 *
 * @param {string} panel Panel name
 */
const CategorySubForm = ({ panel }) => (
  <Grid container direction="row">
    <GridItem>
      <Field component={Input} label="Category ID" name={`${panel}.categoryId`} />
    </GridItem>

    {/* NOTES: 
      - we keep this field disabled for now as we are not sure if the it will be necessary
      - we will keep it or completely remove it depening on the feedback we get from the users
    */}
    <GridItem>
      <Field disabled component={Input} label="Category Name" name={`${panel}.categoryName`} />
    </GridItem>
  </Grid>
);

export default CategorySubForm;