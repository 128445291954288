export const FORM_NAMES = {
  PAGE: 'page',
  CONTENT: 'content',
  NEW_PANEL: 'newPanel',
  NEW_CONTENT: 'newContent',
  NEW_CAROUSEL_PANEL: 'newCarouselPanel',
  NEW_PAGE: 'newPage',
  COPY_PANELS: 'copyPanels',
  EDIT_PANEL_TITLE: 'editPanelTitle',
}

export const BRAND = {
  GRG: 'GARAGE',
  DYN: 'DYNAMITE',
}

/**
 * Keys shall match with page_type column in tb_page
 * 
 * NOTE: HOME_PAGE_V2 is only to temporarily support both new and old Home Page on the Mobile App
 */
export const PAGE_TYPE = {
  HOME_PAGE: 'HOME_PAGE',
  HOME_PAGE_V2: 'HOME_PAGE_V2',
  CATEGORY_PAGE: 'CATEGORY_PAGE',
}

/**
 * Keys shall match with content_type column in tb_content
 */
export const CONTENT_TYPE = {
  SYTE_GALLERY: 'SYTE_GALLERY',
}
