import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { reduxForm, Field, SubmissionError } from 'redux-form'
import { Grid, MenuItem } from '@material-ui/core'
import Input from '../../reusable/input'
import { onCopyPanels } from '../page.redux'
import { LOCALES } from '../page.type'
import { FORM_NAMES } from '../../modules.constants'
import WarningIcon from '@material-ui/icons/Warning'

const useStyles = makeStyles(theme => ({
  warning: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.secondary.main,
    '&>svg': {
      marginRight: '5px',
    },
  },
}))

const CopyPanelsForm = ({ handleSubmit }) => {
  const classes = useStyles()
  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={5}>
          <Field component={Input} select margin="dense" name="copyFrom" label="Copy from" fullWidth>
            {Object.keys(LOCALES).map(locale => (
              <MenuItem key={locale} value={locale}>
                {locale}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={7}></Grid>
        <Grid item xs={5}>
          <Field component={Input} select margin="dense" name="copyTo" label="To" fullWidth>
            {Object.keys(LOCALES).map(locale => (
              <MenuItem key={locale} value={locale}>
                {locale}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={7} className={classes.warning}>
          <WarningIcon color="secondary" />
          Existing panels will be overwritten
        </Grid>
      </Grid>
    </form>
  )
}

const submit = values => {
  const error = {}
  if (!values.copyFrom) error['copyFrom'] = 'Required'
  if (!values.copyTo) {
    error['copyTo'] = 'Required'
  } else if (values.copyFrom === values.copyTo) {
    error['copyTo'] = `Can't be the same`
  }
  if (Object.entries(error).length > 0) throw new SubmissionError(error)
}

export default reduxForm({
  form: FORM_NAMES.COPY_PANELS,
  onSubmit: submit,
  onSubmitSuccess: (_, dispatch) => dispatch(onCopyPanels()),
})(CopyPanelsForm)
