import { createAction, handleActions } from 'redux-actions'
import { LOCALES } from './page.type'
import { Maybe } from '../../utils/utils.types'
import { checkCurrentVersionFlag } from './page.logic'

export const pageMount = createAction('PAGE_PAGE_MOUNT')
export const fetchPageInfos = createAction('PAGE_FETCH_PAGE_INFOS')
export const loadPageVersionInfos = createAction('PAGE_LOAD_PAGE_VERSION_INFOS')
export const changeTab = createAction('PAGE_CHANGE_TAB')
export const addPanel = createAction('PAGE_ADD_PANEL')
export const addCarouselPanel = createAction('PAGE_ADD_CAROUSEL_PANEL')
export const selectPanelIndexAction = createAction('PAGE_SELECT_PANEL_INDEX_ACTION')
export const selectPanelNameAction = createAction('PAGE_SELECT_PANEL_NAME_ACTION')
export const editPanelTitle = createAction('PAGE_EDIT_PANEL_TITLE')
export const reinitField = createAction('PAGE_REINIT_FIELD')
export const switchPanelErrorFlag = createAction('PAGE_SWITCH_PANEL_ERROR_FLAG')
export const switchCarouselPanelErrorFlag = createAction('PAGE_SWITCH_CAROUSEL_PANEL_ERROR_FLAG')
export const switchAltImageVisibleFlag = createAction('PAGE_SWITCH_IMAGE_VISIBLE_FLAG')
export const onSavePage = createAction('PAGE_ON_SAVE_PAGE')
export const savePage = createAction('PAGE_SAVE_PAGE')
export const onDeletePage = createAction('PAGE_ON_DELETE_PAGE')
export const deletePage = createAction('PAGE_DELETE_PAGE')
export const onCopyPanels = createAction('PAGE_ON_COPY_PANELS')
export const copyPanels = createAction('PAGE_COPY_PANELS')
export const showPreview = createAction('PAGE_SHOW_PREVIEW')
export const hidePreview = createAction('PAGE_HIDE_PREVIEW')
export const changeLocalePreview = createAction('PAGE_CHANGE_LOCALE_PREVIEW')
export const changeIsGuestView = createAction('PAGE_CHANGE_IS_GUEST_PREVIEW')
export const showVersions = createAction('PAGE_SHOW_VERSIONS')
export const hideVersions = createAction('PAGE_HIDE_VERSIONS')
export const fetchPageVersions = createAction('PAGE_FETCH_PAGE_VERSIONS')
export const loadPageVersions = createAction('PAGE_LOAD_PAGE_VERSIONS')
export const onClonePageVersion = createAction('PAGE_ON_CLONE_PAGE_VERSION')
export const clonePageVersion = createAction('PAGE_CLONE_PAGE_VERSION')
export const onDeletePageVersion = createAction('PAGE_ON_DELETE_PAGE_VERSION')
export const deletePageVersion = createAction('PAGE_DELETE_PAGE_VERSION')
export const onSelectPageVersion = createAction('PAGE_ON_SELECT_PAGE_VERSION')
export const selectPageVersion = createAction('PAGE_SELECT_PAGE_VERSION')
export const publishPageVersion = createAction('PAGE_PUBLISH_PAGE_VERSION')
export const unpublishPageVersion = createAction('PAGE_UNPUBLISH_PAGE_VERSION')

export const INITIAL_STATE = {
  pageInfos: Maybe.NOTHING,
  selectedTab: 0,
  selectedLocale: null,
  currentPageId: null,
  isOnlineVersion: false,
  isPublishedVersion: false,
  panelNameAction: null,
  preview: {
    isPreviewed: false,
    localePreview: LOCALES.EN_CA,
    isGuestView: false,
  },
  versions: {
    currentVersionId: null,
    isModalVisible: false,
    list: Maybe.NOTHING,
  },
}

export const pageReducer = handleActions(
  {
    [pageMount]: () => INITIAL_STATE,
    [loadPageVersionInfos]: (state, { payload }) => ({
      ...state,
      pageInfos: Maybe.JUST(payload),
      currentPageId: payload.pageId,
      isOnlineVersion: checkCurrentVersionFlag(payload.pageVersionId, state.versions.list.value, 'isOnlineVersion'),
      isPublishedVersion: checkCurrentVersionFlag(payload.pageVersionId, state.versions.list.value, 'isPublished'),
      versions: {
        ...state.versions,
        currentVersionId: payload.pageVersionId,
      },
    }),
    [loadPageVersions]: (state, { payload }) => ({
      ...state,
      isOnlineVersion: checkCurrentVersionFlag(state.versions.currentVersionId, payload, 'isOnlineVersion'),
      isPublishedVersion: checkCurrentVersionFlag(state.versions.currentVersionId, payload, 'isPublished'),
      versions: {
        ...state.versions,
        list: Maybe.JUST(payload),
      },
    }),
    [changeTab]: (state, { payload }) => ({
      ...state,
      selectedTab: payload,
      selectedLocale:
        payload === 1 ? LOCALES.EN_CA : payload === 2 ? LOCALES.EN_US : payload === 3 ? LOCALES.FR_CA : null,
    }),
    [selectPanelNameAction]: (state, { payload }) => ({
      ...state,
      panelNameAction: payload,
    }),
    [showPreview]: state => ({
      ...state,
      preview: {
        ...state.preview,
        isPreviewed: true,
        localePreview: state.selectedLocale || LOCALES.EN_CA,
      },
    }),
    [hidePreview]: state => ({
      ...state,
      preview: {
        ...state.preview,
        isPreviewed: false,
      },
    }),
    [changeLocalePreview]: (state, { payload }) => ({
      ...state,
      preview: {
        ...state.preview,
        localePreview: payload,
      },
    }),
    [changeIsGuestView]: (state, { payload }) => ({
      ...state,
      preview: {
        ...state.preview,
        isGuestView: payload,
      },
    }),
    [showVersions]: state => ({
      ...state,
      versions: {
        ...state.versions,
        isModalVisible: true,
      },
    }),
    [hideVersions]: state => ({
      ...state,
      versions: {
        ...state.versions,
        isModalVisible: false,
      },
    }),
  },
  INITIAL_STATE
)
