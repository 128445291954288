import { of } from 'fluture'
import { get } from 'partial.lenses'
import { getFormValues, arrayInsert, change } from 'redux-form'
import {
  addPanel,
  addCarouselPanel,
  pageMount,
  fetchPageInfos,
  loadPageVersionInfos,
  editPanelTitle,
  reinitField,
  switchPanelErrorFlag,
  switchCarouselPanelErrorFlag,
  switchAltImageVisibleFlag,
  onSavePage,
  savePage,
  onDeletePage,
  deletePage,
  onCopyPanels,
  copyPanels,
  fetchPageVersions,
  loadPageVersions,
  onClonePageVersion,
  clonePageVersion,
  onSelectPageVersion,
  selectPageVersion,
  deletePageVersion,
  hideVersions,
  publishPageVersion,
  unpublishPageVersion,
} from './page.redux'
import { closeModal } from '../modal/modal.redux'
import { displayLoader, hideLoader } from '../loader/loader.redux'
import { getToken, getUserEmail } from '../auth/auth.selector'
import { FORM_NAMES } from '../modules.constants'
import { displayToast, SEVERITY } from '../toast/toast.redux'
import {
  getInitialValuesByPanelType,
  mapResultToPageVersion,
  mapPageVersionsToPage,
  mapPageValuesForSave,
  splitPanelNameAction,
} from './page.logic'
import {
  getPage,
  getPageVersions,
  updatePageVersion,
  deletePageAndPanelsOnCascade,
  copyPanelsFromLocaleToLocale,
  getPageVersion,
  cloneVersion,
  deleteVersion,
  publishVersion,
  unpublishVersion,
} from './page.io'
import { CAROUSSEL_ITEM_INTIAL_VALUES } from './page.type'
import { getApiErrorMessage, isExpiredSession } from '../../utils/utils'
import { setRedirectPath, logout } from '../auth/auth.redux'
import { getCurrentPageId } from './page.selector'

const pageMountEpic = {
  type: pageMount.toString(),
  do: ({ payload }) => of([displayLoader(), fetchPageInfos(payload)]),
}

const fetchPageInfosEpic = {
  type: fetchPageInfos.toString(),
  do: ({ payload: { pageId, versionId } }, store) =>
    (versionId ? getPageVersion(pageId, versionId, getToken(store)) : getPage(pageId, getToken(store)))
      .map(({ data }) => mapResultToPageVersion(data))
      .map(pageVersionInfos => [loadPageVersionInfos(pageVersionInfos), fetchPageVersions(pageId)])
      .mapRej(error =>
        isExpiredSession(error)
          ? [
              displayToast({
                message: `Your session has expired. You've been logged out.`,
                severity: SEVERITY.WARNING,
              }),
              logout(),
              hideLoader(),
            ]
          : [
              displayToast({ message: getApiErrorMessage(error), severity: SEVERITY.ERROR }),
              setRedirectPath('/'),
              hideLoader(),
            ]
      ),
}

const fetchPageVersionsEpic = {
  type: fetchPageVersions.toString(),
  do: ({ payload: pageId }, store) =>
    getPageVersions(pageId, getToken(store))
      .map(({ data }) => mapPageVersionsToPage(data))
      .map(pageVersions => [loadPageVersions(pageVersions), hideLoader()])
      .mapRej(error => [displayToast({ message: getApiErrorMessage(error), severity: SEVERITY.ERROR }), hideLoader()]),
}

const onSelectPageVersionEpic = {
  type: onSelectPageVersion.toString(),
  do: ({ payload: pageVersionId }) => of([displayLoader(), selectPageVersion(pageVersionId)]),
}

const selectPageVersionEpic = {
  type: selectPageVersion.toString(),
  do: ({ payload: pageVersionId }, store) => {
    window.history.pushState('', '', `/page/${getCurrentPageId(store)}/version/${pageVersionId}`)
    return getPageVersion(getCurrentPageId(store), pageVersionId, getToken(store))
      .map(({ data }) => mapResultToPageVersion(data))
      .map(pageVersionInfos => [loadPageVersionInfos(pageVersionInfos), hideLoader(), hideVersions()])
      .mapRej(error => [
        displayToast({ message: getApiErrorMessage(error), severity: SEVERITY.ERROR }),
        setRedirectPath('/'),
        hideLoader(),
      ])
  },
}

const onClonePageVersionEpic = {
  type: onClonePageVersion.toString(),
  do: ({ payload: pageVersionId }) => of([displayLoader(), clonePageVersion(pageVersionId)]),
}

const clonePageVersionEpic = {
  type: clonePageVersion.toString(),
  do: ({ payload: pageVersionId }, store) =>
    cloneVersion(pageVersionId, getUserEmail(store), getToken(store))
      .map(() => [fetchPageVersions(getCurrentPageId(store))])
      .mapRej(error => [displayToast({ message: getApiErrorMessage(error), severity: SEVERITY.ERROR }), hideLoader()]),
}

const deletePageVersionEpic = {
  type: deletePageVersion.toString(),
  do: ({ payload: pageVersionId }, store) =>
    deleteVersion(pageVersionId, getToken(store)).map(() => [fetchPageVersions(getCurrentPageId(store))]),
}

const publishPageVersionEpic = {
  type: publishPageVersion.toString(),
  do: ({ payload: pageVersionId }, store) =>
    publishVersion(pageVersionId, getUserEmail(store), getToken(store))
      .map(() => fetchPageVersions(getCurrentPageId(store)))
      .mapRej(error => displayToast({ message: getApiErrorMessage(error), severity: SEVERITY.ERROR })),
}

const unpublishPageVersionEpic = {
  type: unpublishPageVersion.toString(),
  do: ({ payload: pageVersionId }, store) =>
    unpublishVersion(pageVersionId, getUserEmail(store), getToken(store))
      .map(() => fetchPageVersions(getCurrentPageId(store)))
      .mapRej(error => displayToast({ message: getApiErrorMessage(error), severity: SEVERITY.ERROR })),
}

const addPanelEpic = {
  type: addPanel.toString(),
  do: (_, store) => {
    const newPanelValues = getFormValues(FORM_NAMES.NEW_PANEL)(store)
    const panelNameAction = get(['page', 'panelNameAction'], store) + 1
    const selectedLocale = get(['page', 'selectedLocale'], store)

    const { panelName, panelIndex } = splitPanelNameAction(panelNameAction)

    return of([
      arrayInsert(
        FORM_NAMES.PAGE,
        `${selectedLocale}.${panelName}`,
        Number(panelIndex) + 1,
        getInitialValuesByPanelType(newPanelValues)
      ),
      closeModal(),
    ])
  },
}

const addCarouselPanelEpic = {
  type: addCarouselPanel.toString(),
  do: (_, store) => {
    const newPanelValues = getFormValues(FORM_NAMES.NEW_CAROUSEL_PANEL)(store)
    const panelNameAction = get(['page', 'panelNameAction'], store) + 1
    const selectedLocale = get(['page', 'selectedLocale'], store)

    const { panelName, panelIndex } = splitPanelNameAction(panelNameAction)

    return of([
      arrayInsert(FORM_NAMES.PAGE, `${selectedLocale}.${panelName}`, Number(panelIndex) + 1, {
        ...CAROUSSEL_ITEM_INTIAL_VALUES,
        title: newPanelValues.title,
      }),
      closeModal(),
    ])
  },
}

const onSavePageEpic = {
  type: onSavePage.toString(),
  do: () => of([displayLoader(), savePage()]),
}

const switchPanelErrorFlagEpic = {
  type: switchPanelErrorFlag.toString(),
  do: ({ payload: { locale, panelIndex, hasError } }) =>
    of(change(FORM_NAMES.PAGE, `${locale}.panels[${panelIndex}].hasError`, hasError)),
}

const switchCarouselPanelErrorFlagEpic = {
  type: switchCarouselPanelErrorFlag.toString(),
  do: ({ payload: { locale, panelIndex, carouselPanelIndex, hasError } }) =>
    of(change(FORM_NAMES.PAGE, `${locale}.panels[${panelIndex}].panels[${carouselPanelIndex}].hasError`, hasError)),
}

const switchAltImageVisibleFlagEpic = {
  type: switchAltImageVisibleFlag.toString(),
  do: ({ payload: { locale, panelIndex, isAltImageVisible } }) =>
    of(change(FORM_NAMES.PAGE, `${locale}.panels[${panelIndex}].isAltImageVisible`, isAltImageVisible)),
}

const savePageEpic = {
  type: savePage.toString(),
  do: (_, store) => {
    const { pageId, pageVersionId, ...pageValues } = getFormValues(FORM_NAMES.PAGE)(store)

    return updatePageVersion(pageVersionId, mapPageValuesForSave(pageValues), getUserEmail(store), getToken(store))
      .map(({ data }) => mapResultToPageVersion(data))
      .map(pageVersionInfos => [
        loadPageVersionInfos(pageVersionInfos),
        fetchPageVersions(pageId),
        displayToast({ message: 'Changes saved successfully!', severity: SEVERITY.SUCCESS }),
      ])
      .mapRej(error => [displayToast({ message: getApiErrorMessage(error), severity: SEVERITY.ERROR }), hideLoader()])
  },
}

const onDeletePageEpic = {
  type: onDeletePage.toString(),
  do: () => of([displayLoader(), deletePage()]),
}

const deletePageEpic = {
  type: deletePage.toString(),
  do: (_, store) =>
    deletePageAndPanelsOnCascade(getCurrentPageId(store), getToken(store))
      .map(() => [
        hideLoader(),
        setRedirectPath('/'),
        displayToast({ message: 'Page deleted successfully!', severity: SEVERITY.SUCCESS }),
      ])
      .mapRej(error => [displayToast({ message: getApiErrorMessage(error), severity: SEVERITY.ERROR }), hideLoader()]),
}

const onCopyPanelsEpic = {
  type: onCopyPanels.toString(),
  do: () => of([displayLoader(), copyPanels()]),
}

const copyPanelsEpic = {
  type: copyPanels.toString(),
  do: (_, store) => {
    const { pageId, pageVersionId, ...pageValues } = getFormValues(FORM_NAMES.PAGE)(store)
    const params = getFormValues(FORM_NAMES.COPY_PANELS)(store)
    return updatePageVersion(pageVersionId, pageValues, getUserEmail(store), getToken(store))
      .chain(() => copyPanelsFromLocaleToLocale(pageVersionId, params, getUserEmail(store), getToken(store)))
      .map(() => [
        closeModal(),
        selectPageVersion(pageVersionId),
        displayToast({ message: 'Panels have been copied successfully!', severity: SEVERITY.SUCCESS }),
      ])
      .mapRej(error => [displayToast({ message: getApiErrorMessage(error), severity: SEVERITY.ERROR }), hideLoader()])
  },
}

const editPanelTitleEpic = {
  type: editPanelTitle.toString(),
  do: (_, store) => {
    const selectedLocale = get(['page', 'selectedLocale'], store)
    const panelNameAction = get(['page', 'panelNameAction'], store)
    const editPanelTitleValues = getFormValues(FORM_NAMES.EDIT_PANEL_TITLE)(store)

    return of([
      closeModal(),
      change(FORM_NAMES.PAGE, `${selectedLocale}.${panelNameAction}.title`, editPanelTitleValues.title),
      /* NOTES:
        - adding the title to the category name as well because this field is disabled for now
        - we will remove or keep the category name field depending on the users feedback
      */
      change(FORM_NAMES.PAGE, `${selectedLocale}.${panelNameAction}.categoryName`, editPanelTitleValues.title),
    ])
  },
}

const reinitFieldEpic = {
  type: reinitField.toString(),
  do: ({ payload: { panel, field, value } }, store) => {
    const selectedLocale = get(['page', 'selectedLocale'], store)

    return of(change(FORM_NAMES.PAGE, `${selectedLocale}.${panel}.${field}`, value || value === false ? value : null))
  },
}

export default [
  pageMountEpic,
  fetchPageInfosEpic,
  fetchPageVersionsEpic,
  onSelectPageVersionEpic,
  selectPageVersionEpic,
  onClonePageVersionEpic,
  clonePageVersionEpic,
  deletePageVersionEpic,
  publishPageVersionEpic,
  unpublishPageVersionEpic,
  addPanelEpic,
  addCarouselPanelEpic,
  switchPanelErrorFlagEpic,
  switchCarouselPanelErrorFlagEpic,
  switchAltImageVisibleFlagEpic,
  onSavePageEpic,
  savePageEpic,
  onDeletePageEpic,
  deletePageEpic,
  onCopyPanelsEpic,
  copyPanelsEpic,
  editPanelTitleEpic,
  reinitFieldEpic,
]
