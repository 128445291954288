import authEpics from '../modules/auth/auth.epic'
import pageEpics from '../modules/page/page.epic'
import contentEpics from '../modules/content/content.epic'
import homeEpics from '../modules/home/home.epic'

export const epics = {
  authEpics,
  pageEpics,
  contentEpics,
  homeEpics,
}
