import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { TextField, Tooltip } from '@material-ui/core'

const styles = {
  input: {
    backgroundColor: 'white',
  },
}

const ErrorTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#f44336',
    color: '#fff',
    fontSize: 11,
  },
  arrow: {
    color: '#f44336',
  },
}))(Tooltip)

const Input = ({ label, input, min, max, step, maxLength, meta: { touched, invalid, error }, classes, ...custom }) => {
  return error ? (
    <ErrorTooltip title={error} placement="left" arrow>
      <TextField
        fullWidth
        variant="outlined"
        label={label}
        error
        inputProps={{
          className: classes.input,
          min,
          max,
          step,
          maxLength,
        }}
        {...input}
        {...custom}
      />
    </ErrorTooltip>
  ) : (
    <TextField
      fullWidth
      variant="outlined"
      label={label}
      inputProps={{
        className: classes.input,
        min,
        max,
        step,
        maxLength,
      }}
      {...input}
      {...custom}
    />
  )
}

export default withStyles(styles)(Input)
