import React from 'react'
import { reduxForm, Field, SubmissionError } from 'redux-form'
import { Grid, MenuItem } from '@material-ui/core'
import Input from '../../reusable/input'
import { FORM_NAMES } from '../../modules.constants'
import { onAddContent } from '../home.redux'
import { CONTENT_TYPE, BRAND } from '../../modules.constants'

const NewContentForm = ({ handleSubmit }) => (
  <form autoComplete="off" onSubmit={handleSubmit}>
    <Grid container direction="row" spacing={2}>
      <Grid item xs={4}>
        <Field component={Input} select margin="dense" name="contentType" label="Content Type" fullWidth>
          {Object.entries(CONTENT_TYPE).map(([key, value]) => (
            <MenuItem value={key}>{value.replace('_', ' ')}</MenuItem>
          ))}
        </Field>
      </Grid>
      <Grid item xs={4}>
        <Field component={Input} select margin="dense" name="brand" label="Brand" fullWidth>
          {Object.entries(BRAND).map(([key, value]) => (
            <MenuItem value={key}>{value}</MenuItem>
          ))}
        </Field>
      </Grid>
    </Grid>
  </form>
)

const submit = values => {
  const error = {}
  if (!values.contentType) error['contentType'] = 'Required'
  if (!values.brand) error['brand'] = 'Required'
  if (Object.entries(error).length > 0) throw new SubmissionError(error)
}

export default reduxForm({
  form: FORM_NAMES.NEW_CONTENT,
  onSubmit: submit,
  onSubmitSuccess: (_, dispatch) => dispatch(onAddContent()),
})(NewContentForm)
