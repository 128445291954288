import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core'
import { Preview } from './preview'
import { hidePreview } from '../../page.redux'
import { PreviewMenu } from './previewMenu'

export const PreviewModal = () => {
  const dispatch = useDispatch()
  const { isPreviewed } = useSelector(state => state.page.preview)

  return (
    <Dialog fullWidth={true} open={isPreviewed} onClose={() => dispatch(hidePreview())}>
      <DialogTitle id="form-dialog-title">Preview</DialogTitle>
      <DialogContent dividers>
        <PreviewMenu />
        <Preview />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(hidePreview())} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
