import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core'
import { hidePreview } from '../content.redux'
import { PreviewGalleryImages } from './syteGallery/preview'

export const PreviewModal = () => {
  const {
    preview: { isPreviewed, previewValues },
    contentType,
  } = useSelector(state => state.content)
  const dispatch = useDispatch()

  return (
    <Dialog maxWidth="md" fullWidth={true} open={isPreviewed} onClose={() => dispatch(hidePreview())}>
      <DialogTitle id="form-dialog-title">Preview</DialogTitle>
      <DialogContent dividers>
        {contentType === 'SYTE_GALLERY' && <PreviewGalleryImages previewValues={previewValues} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(hidePreview())} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
