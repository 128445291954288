import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { Layout } from './layout/Layout'
import { Routes } from './router'
import { useDispatch, useSelector } from 'react-redux'
import { refreshToken, setRedirectPath } from './modules/auth/auth.redux'
import Loader from './modules/loader/components/loader'
import Modal from './modules/modal/components/modal'
import Toast from './modules/toast/components/toast'

const App = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { redirectPath } = useSelector(state => state.auth)

  useEffect(() => {
    dispatch(refreshToken())
  }, [dispatch])

  useEffect(() => {
    redirectPath !== null && history.push(redirectPath)
    dispatch(setRedirectPath(null))
  }, [dispatch, history, redirectPath])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Layout>
        <Routes />
        <Loader />
        <Modal />
        <Toast />
      </Layout>
    </MuiPickersUtilsProvider>
  )
}

export default App
