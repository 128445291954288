import { of } from 'fluture'
import {
  contentMount,
  onDeleteContent,
  deleteContent,
  fetchContentInfos,
  loadContentInfos,
  onSaveContent,
  saveContent,
  showPreview,
  loadPreviewValues,
} from './content.redux'
import { displayLoader, hideLoader } from '../loader/loader.redux'
import { displayToast, SEVERITY } from '../toast/toast.redux'
import { setRedirectPath, logout } from '../auth/auth.redux'
import { getApiErrorMessage, isExpiredSession } from '../../utils/utils'
import { getToken, getUserEmail } from '../auth/auth.selector'
import { deleteContentIO, getContentIO, updateContentIO } from './content.io'
import { mapResultToContent, reduceGalleryImages } from './content.logic'
import { getCurrentContentId } from './content.selector'
import { getFormValues } from 'redux-form'
import { FORM_NAMES } from '../modules.constants'

const contentMountEpic = {
  type: contentMount.toString(),
  do: ({ payload }) => of([displayLoader(), fetchContentInfos(payload)]),
}

const fetchContentInfosEpic = {
  type: fetchContentInfos.toString(),
  do: ({ payload: { contentId } }, store) =>
    getContentIO(contentId, getToken(store))
      .map(({ data }) => mapResultToContent(data))
      .map(contentInfos => [loadContentInfos(contentInfos), hideLoader()])

      .mapRej(error =>
        isExpiredSession(error)
          ? [
              displayToast({
                message: `Your session has expired. You've been logged out.`,
                severity: SEVERITY.WARNING,
              }),
              logout(),
              hideLoader(),
            ]
          : [
              displayToast({ message: getApiErrorMessage(error), severity: SEVERITY.ERROR }),
              setRedirectPath('/'),
              hideLoader(),
            ]
      ),
}

const onSaveContentEpic = {
  type: onSaveContent.toString(),
  do: () => of([displayLoader(), saveContent()]),
}

const saveContentEpic = {
  type: saveContent.toString(),
  do: (_, store) => {
    const { contentId, ...contentValues } = getFormValues(FORM_NAMES.CONTENT)(store)

    return updateContentIO(contentId, contentValues, getUserEmail(store), getToken(store))
      .map(() => [hideLoader(), displayToast({ message: 'Changes saved successfully!', severity: SEVERITY.SUCCESS })])
      .mapRej(error => [displayToast({ message: getApiErrorMessage(error), severity: SEVERITY.ERROR }), hideLoader()])
  },
}

const onDeleteContentEpic = {
  type: onDeleteContent.toString(),
  do: () => of([displayLoader(), deleteContent()]),
}

const deleteContentEpic = {
  type: deleteContent.toString(),
  do: (_, store) =>
    deleteContentIO(getCurrentContentId(store), getToken(store))
      .map(() => [
        hideLoader(),
        setRedirectPath('/'),
        displayToast({ message: 'Content deleted successfully!', severity: SEVERITY.SUCCESS }),
      ])
      .mapRej(error => [displayToast({ message: getApiErrorMessage(error), severity: SEVERITY.ERROR }), hideLoader()]),
}

const showPreviewEpic = {
  type: showPreview.toString(),
  do: (_, store) => {
    const { galleryImages } = getFormValues(FORM_NAMES.CONTENT)(store)

    return of(loadPreviewValues(reduceGalleryImages(galleryImages, 3)))
  },
}

export default [
  contentMountEpic,
  fetchContentInfosEpic,
  onSaveContentEpic,
  saveContentEpic,
  onDeleteContentEpic,
  deleteContentEpic,
  showPreviewEpic,
]
