import React, { useEffect } from 'react'
import { submit } from 'redux-form'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { pageMount } from '../page.redux'
import PageForm from './pageForm'
import { PlayArrow, Delete, Save, Storage, FileCopy } from '@material-ui/icons'
import { showPreview, onDeletePage, showVersions } from '../page.redux'
import { openModal } from '../../modal/modal.redux'
import { FORM_NAMES } from '../../modules.constants'
import { PreviewModal } from './preview/previewModal'
import { PageVersionsModal } from './pageVersionsModal'
import { BottomMenu } from '../../reusable/bottomMenu'

export const Page = () => {
  const dispatch = useDispatch()
  const { pageId, versionId } = useParams()
  const leftItems = [
    {
      type: 'button',
      value: 'Save',
      onClickCallback: () => dispatch(submit(FORM_NAMES.PAGE)),
      startIcon: <Save />,
    },
    {
      type: 'button',
      value: 'Delete',
      onClickCallback: () =>
        dispatch(
          openModal({
            textHeader: 'Delete page',
            textContent:
              'Are you sure you want to delete this page and all its versions? This action will be irreversible.',
            callback: () => dispatch(onDeletePage()),
            autoClose: true,
          })
        ),
      startIcon: <Delete />,
    },
    {
      type: 'button',
      value: 'Page versions',
      onClickCallback: () => dispatch(showVersions()),
      startIcon: <Storage />,
    },
  ]
  const rightItems = [
    {
      type: 'button',
      value: 'Preview',
      onClickCallback: () => dispatch(showPreview()),
      startIcon: <PlayArrow />,
    },
    {
      type: 'button',
      value: 'Copy locale panels',
      onClickCallback: () =>
        dispatch(
          openModal({
            textHeader: 'Copy locale panels',
            textContent: 'Copy all panels from one locale to the other',
            form: FORM_NAMES.COPY_PANELS,
            autoClose: false,
          })
        ),
      startIcon: <FileCopy />,
    },
  ]

  useEffect(() => {
    dispatch(pageMount({ pageId, versionId }))
  }, [dispatch, pageId, versionId])

  return (
    <>
      <PageForm />
      <BottomMenu leftItems={leftItems} rightItems={rightItems} />
      <PreviewModal />
      <PageVersionsModal />
    </>
  )
}
