import React from 'react'
import { reduxForm, Field, SubmissionError } from 'redux-form'
import { Grid } from '@material-ui/core'
import Input from '../../../reusable/input'
import { addCarouselPanel } from '../../page.redux'
import { FORM_NAMES } from '../../../modules.constants'

const NewCarouselPanelForm = ({ handleSubmit }) => (
  <form autoComplete="off" onSubmit={handleSubmit}>
    <Grid container direction="row" spacing={2}>
      <Grid item xs={6}>
        <Field component={Input} autoFocus name="title" margin="dense" label="Title" fullWidth />
      </Grid>
    </Grid>
  </form>
)

const submit = values => {
  const error = {}
  if (!values.title) error['title'] = 'Required'
  if (Object.entries(error).length > 0) throw new SubmissionError(error)
}

export default reduxForm({
  form: FORM_NAMES.NEW_CAROUSEL_PANEL,
  onSubmit: submit,
  onSubmitSuccess: (_, dispatch) => dispatch(addCarouselPanel()),
})(NewCarouselPanelForm)
