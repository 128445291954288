import { get } from 'partial.lenses'

/**
 * Get API error message as string value or 'Internal Server Error' message by default
 *
 * @param {Object} error error object
 * @returns {string} error message
 */
export const getApiErrorMessage = error =>
  get(
    [
      'response',
      ({ data, status }) =>
        status === 404
          ? 'The requested URL was not found on this server'
          : typeof data === 'string' || data instanceof String
          ? data
          : 'Internal Server Error',
    ],
    error
  )

/**
 * Check if error message returned from API call notice expired session
 *
 * @param {Object} params
 * @param {Object} params.response Response object
 * @returns {boolean} True if session has expired
 */
export const isExpiredSession = ({ response }) =>
  response.status === 401 && response.data === 'Invalid or not provided token'

export const getStagingImagePathRegex = new RegExp(
  '(http|https)://staging-na01-dynamite.demandware.net/on/demandware.static/-/Library-Sites-global_library_ca/default/'
)

export const getProductionImagePathRegex = new RegExp(
  '(http|https)://www.(garage|dynamite)clothing.com/on/demandware.static/-/Library-Sites-global_library_ca/default/'
)

/**
 * Test if an url match with staging or production image path pattern
 *
 * @param {string} url URL
 * @returns {boolean}
 */
export const isStagingOrProductionImagePath = url =>
  getStagingImagePathRegex.test(url) || getProductionImagePathRegex.test(url)
