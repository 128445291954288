import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Toolbar, AppBar } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - 260px)`,
      marginLeft: '260px',
    },
    top: 'auto',
    bottom: 0,
  },
  button: {
    marginRight: '1rem',
  },
  separation: {
    flexGrow: 1,
  },
}))

export const BottomMenu = ({ leftItems, rightItems }) => {
  const classes = useStyles()

  return (
    <AppBar position="fixed" color="primary" className={classes.appBar}>
      <Toolbar>
        {leftItems &&
          leftItems.map(
            item =>
              item.type === 'button' && (
                <Button
                  key={item.value}
                  size="large"
                  variant="contained"
                  onClick={item.onClickCallback}
                  className={classes.button}
                  startIcon={item.startIcon}
                >
                  {item.value}
                </Button>
              )
          )}
        <div className={classes.separation} />
        {rightItems &&
          rightItems.map(
            item =>
              item.type === 'button' && (
                <Button
                  key={item.value}
                  size="large"
                  variant="contained"
                  onClick={item.onClickCallback}
                  className={classes.button}
                  startIcon={item.startIcon}
                >
                  {item.value}
                </Button>
              )
          )}
      </Toolbar>
    </AppBar>
  )
}
