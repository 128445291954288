import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Field } from 'redux-form'
import { Grid } from '@material-ui/core'
import PanelButtons from './panelButtons'
import Input from '../../../reusable/input'

const GridItemWithPadding = withStyles({
  root: {
    padding: '16px 12px 0 12px',
  },
})(Grid)

const FooterSubForm = ({ fields }) => (
  <Grid container direction="row">
    {fields.map((footerItem, index) => (
      <>
        <GridItemWithPadding xs={5} item>
          <Field component={Input} label="Label" name={`${footerItem}.label`} />
        </GridItemWithPadding>
        <GridItemWithPadding xs={5} item>
          <Field component={Input} label="URL" name={`${footerItem}.itemUrl`} />
        </GridItemWithPadding>
        <GridItemWithPadding xs={2} item style={{ paddingTop: '20px' }}>
          <PanelButtons
            index={index}
            onSwapUp={() => fields.swap(index - 1, index)}
            onSwapDown={() => fields.swap(index, index + 1)}
            onAdd={{ callback: () => fields.insert(index + 1, {}), label: 'Add new label' }}
            onRemove={{
              callback: () => fields.remove(index),
              label: 'Remove label',
            }}
            panelCount={fields.length}
            panelLimit={4}
          />
        </GridItemWithPadding>
      </>
    ))}
  </Grid>
)

export default FooterSubForm
