import { tryP } from 'fluture'
import axios from 'axios'
import { API_URL } from '../../const'

export const getPage = (pageId, token) =>
  tryP(() =>
    axios({
      method: 'GET',
      url: `api/pages/${pageId}`,
      baseURL: API_URL,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )

export const getPageVersions = (pageId, token) =>
  tryP(() =>
    axios({
      method: 'GET',
      url: `api/pages/${pageId}/versions`,
      baseURL: API_URL,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )

export const deletePageAndPanelsOnCascade = (pageId, token) =>
  tryP(() =>
    axios({
      method: 'DELETE',
      url: `api/pages/${pageId}`,
      baseURL: API_URL,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )

export const copyPanelsFromLocaleToLocale = (pageVersionId, data, userEmail, token) =>
  tryP(() =>
    axios({
      method: 'PUT',
      url: `api/page-versions/${pageVersionId}/copy-panels`,
      baseURL: API_URL,
      data: { ...data, userEmail },
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )

export const updatePageVersion = (pageVersionId, data, userEmail, token) =>
  tryP(() =>
    axios({
      method: 'PUT',
      url: `api/page-versions/${pageVersionId}`,
      baseURL: API_URL,
      data: { ...data, userEmail },
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )

export const getPageVersion = (pageId, pageVersionId, token) =>
  tryP(() =>
    axios({
      method: 'GET',
      url: `api/page-versions/${pageId}/${pageVersionId}`,
      baseURL: API_URL,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )

export const cloneVersion = (pageVersionId, userEmail, token) =>
  tryP(() =>
    axios({
      method: 'POST',
      url: `api/page-versions/${pageVersionId}/clone`,
      baseURL: API_URL,
      data: { userEmail },
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )

export const deleteVersion = (pageVersionId, token) =>
  tryP(() =>
    axios({
      method: 'DELETE',
      url: `api/page-versions/${pageVersionId}`,
      baseURL: API_URL,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )

export const publishVersion = (pageVersionId, userEmail, token) =>
  tryP(() =>
    axios({
      method: 'PUT',
      url: `api/page-versions/${pageVersionId}/publish`,
      baseURL: API_URL,
      data: { userEmail },
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )

export const unpublishVersion = (pageVersionId, userEmail, token) =>
  tryP(() =>
    axios({
      method: 'PUT',
      url: `api/page-versions/${pageVersionId}/unpublish`,
      baseURL: API_URL,
      data: { userEmail },
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )
