import { createAction, handleActions } from 'redux-actions'

export const closeModal = createAction('MODAL_CLOSE_MODAL')
export const openModal = createAction('MODAL_OPEN_MODAL')

export const INITIAL_STATE = {
  isOpen: false,
  autoClose: true,
  textHeader: null,
  textContent: null,
  additionalContent: null,
  callback: null,
  imagePreview: null,
  videoPreview: null,
  maxWidth: 'sm',
  form: null,
  hasSoundEnabled: false,
}

export const modalReducer = handleActions(
  {
    [closeModal]: () => ({
      ...INITIAL_STATE,
    }),
    [openModal]: (state, { payload }) => ({
      ...state,
      ...payload,
      isOpen: true,
    }),
  },
  INITIAL_STATE
)
