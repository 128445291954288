import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, MenuItem, TextField, FormControlLabel } from '@material-ui/core'
import { LOCALES } from '../../page.type'
import { changeLocalePreview, changeIsGuestView } from '../../page.redux'
import MaterialUICheckbox from '@material-ui/core/Checkbox'

const useStyles = makeStyles(() => ({
  gridMenu: {
    marginBottom: '8px',
  },
}))

export const PreviewMenu = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { localePreview, isGuestView } = useSelector(state => state.page.preview)

  return (
    <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.gridMenu} spacing={2}>
      <Grid item xs={6}>
        <TextField
          select
          fullWidth
          variant="outlined"
          label="Locale"
          name="locale"
          onChange={event => dispatch(changeLocalePreview(event.target.value))}
          value={localePreview}
        >
          <MenuItem value={LOCALES.EN_CA}>{LOCALES.EN_CA}</MenuItem>
          <MenuItem value={LOCALES.EN_US}>{LOCALES.EN_US}</MenuItem>
          <MenuItem value={LOCALES.FR_CA}>{LOCALES.FR_CA}</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <MaterialUICheckbox
              checked={isGuestView}
              onChange={event => dispatch(changeIsGuestView(event.target.checked))}
              color="primary"
            />
          }
          label="Guest view"
        />
      </Grid>
    </Grid>
  )
}
