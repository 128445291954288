import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import WarningIcon from '@material-ui/icons/Warning'

const useStyles = makeStyles(theme => ({
  paper: {
    textAlign: 'center',
    padding: '20px',
    margin: '50px auto',
    color: theme.palette.primary.main,
    '&>p': {
      fontSize: '24px',
      fontWeight: 'bold',
    },
  },
  warningIcon: {
    fontSize: 125,
  },
}))

export const PageNotFound = () => {
  const classes = useStyles()

  return (
    <div className={classes.paper}>
      <WarningIcon className={classes.warningIcon} />
      <p>Page not found</p>
    </div>
  )
}
