import { of, parallel } from 'fluture'
import {
  homeMount,
  fetchHomeInfos,
  loadPageList,
  loadContentList,
  addPage,
  onAddPage,
  addContent,
  onAddContent,
} from './home.redux'
import { listPages, listContent, createPage, createContent } from './home.io'
import { getFormValues } from 'redux-form'
import { displayLoader, hideLoader } from '../loader/loader.redux'
import { displayToast, SEVERITY } from '../toast/toast.redux'
import { closeModal } from '../modal/modal.redux'
import { FORM_NAMES } from '../modules.constants'
import { getToken, getUserEmail } from '../auth/auth.selector'
import { getApiErrorMessage, isExpiredSession } from '../../utils/utils'
import { logout } from '../auth/auth.redux'

const homeMountEpic = {
  type: homeMount.toString(),
  do: () => of([displayLoader(), fetchHomeInfos({ fetchPages: true, fetchContents: true })]),
}

const fetchHomeInfosEpic = {
  type: fetchHomeInfos.toString(),
  do: ({ payload: { fetchPages, fetchContents } }, store) =>
    parallel(Infinity, [
      fetchPages ? listPages(getToken(store)) : of(),
      fetchContents ? listContent(getToken(store)) : of(),
    ])
      .map(([pageListResult, contentListResult]) => [
        ...(fetchPages ? [loadPageList(pageListResult.data)] : []),
        ...(fetchContents ? [loadContentList(contentListResult.data)] : []),
        hideLoader(),
      ])
      .mapRej(error =>
        isExpiredSession(error)
          ? [
              displayToast({
                message: `Your session has expired. You've been logged out.`,
                severity: SEVERITY.WARNING,
              }),
              logout(),
              hideLoader(),
            ]
          : [displayToast({ message: getApiErrorMessage(error), severity: SEVERITY.ERROR }), hideLoader()]
      ),
}

const onAddPageEpic = {
  type: onAddPage.toString(),
  do: () => of([displayLoader(), addPage()]),
}

const addPageEpic = {
  type: addPage.toString(),
  do: (_, store) => {
    const newPageValues = getFormValues(FORM_NAMES.NEW_PAGE)(store)
    return createPage(newPageValues, getUserEmail(store), getToken(store))
      .map(() => [
        fetchHomeInfos({ fetchPages: true, fetchContents: false }),
        displayToast({ message: 'The new page has been created', severity: SEVERITY.SUCCESS }),
        closeModal(),
      ])
      .mapRej(error => [
        displayToast({ message: getApiErrorMessage(error), severity: SEVERITY.ERROR }),
        closeModal(),
        hideLoader(),
      ])
  },
}

const onAddContentEpic = {
  type: onAddContent.toString(),
  do: () => of([displayLoader(), addContent()]),
}

const addContentEpic = {
  type: addContent.toString(),
  do: (_, store) => {
    const newContentValues = getFormValues(FORM_NAMES.NEW_CONTENT)(store)
    return createContent(newContentValues, getUserEmail(store), getToken(store))
      .map(() => [
        fetchHomeInfos({ fetchPages: false, fetchContents: true }),
        displayToast({ message: 'The new content has been created', severity: SEVERITY.SUCCESS }),
        closeModal(),
      ])
      .mapRej(error => [
        displayToast({ message: getApiErrorMessage(error), severity: SEVERITY.ERROR }),
        closeModal(),
        hideLoader(),
      ])
  },
}

export default [homeMountEpic, fetchHomeInfosEpic, onAddPageEpic, addPageEpic, onAddContentEpic, addContentEpic]
