import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  wrapperStyle: {
    position: 'absolute',
    width: '100%',
    zIndex: '100',
    bottom: '0px',
    textAlign: 'center',
  },
  dot: {
    display: 'inline-block',
    height: '6px',
    width: '6px',
    borderRadius: '4px',
    border: '1px solid #000',
    margin: '7px 5px',
    transitionDuration: '300ms',
  },
}))

export const CarouselDots = ({ total, index }) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapperStyle}>
      {Array.apply(null, Array(total)).map((_, i) => (
        <span
          key={i}
          style={{ backgroundColor: `rgba(0, 0, 0, ${index === i ? '0' : '1'})` }}
          className={classes.dot}
        />
      ))}
    </div>
  )
}
