import React from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Login } from './modules/auth/components/login'
import { Home } from './modules/home/components/home'
import { Page } from './modules/page/components/page'
import { Content } from './modules/content/components/content'
import { PageNotFound } from './modules/pageNotFound/pageNotFound'
import { HealthPage } from './modules/health/health'

const ConditionalRoute = ({ component: Component, needsAuth, ...props }) => {
  const { authenticated } = useSelector(state => state.auth)

  if (needsAuth && !authenticated) return <Redirect to="/login" />

  if (props.path === '/login' && authenticated) return <Redirect to="/" />

  return <Route {...props} component={Component} />
}

export const Routes = () => (
  <Switch>
    <ConditionalRoute component={Login} path="/login" needsAuth={false} />
    <ConditionalRoute exact component={Home} path="/" needsAuth={true} />
    <ConditionalRoute component={Page} path={['/page/:pageId/version/:versionId', '/page/:pageId']} needsAuth={true} />
    <ConditionalRoute component={Content} path="/content/:contentId" needsAuth={true} />
    <Route component={HealthPage} path="/health" />
    <ConditionalRoute component={PageNotFound} path="*" needsAuth={true} />
  </Switch>
)
