import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { submit } from 'redux-form'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import NewPanelForm from '../../page/components/panels/newPanelForm'
import NewCarouselPanelForm from '../../page/components/panels/newCarouselPanelForm'
import EditPanelTitleForm from '../../page/components/panels/editPanelTitleForm'
import CopyPanelsForm from '../../page/components/copyPanelsForm'
import NewPageForm from '../../home/components/newPageForm'
import NewContentForm from '../../home/components/newContentForm'
import { closeModal } from '../modal.redux'
import { FORM_NAMES } from '../../modules.constants'
import imageNotFound from '../../../assets/images/image-not-found.jpg'
import { STAGING_IMAGE_PATH_PREFIX } from '../../../const'
import { VideoPlayer } from '../../reusable/videoPlayer'

const useStyles = makeStyles(() => ({
  imagePreviewDialog: {
    textAlign: 'center',
  },
  imagePreview: {
    maxWidth: '100%',
  },
}))

const displayForm = formName => {
  switch (formName) {
    case FORM_NAMES.NEW_PANEL:
      return <NewPanelForm />
    case FORM_NAMES.NEW_CAROUSEL_PANEL:
      return <NewCarouselPanelForm />
    case FORM_NAMES.NEW_PAGE:
      return <NewPageForm />
    case FORM_NAMES.NEW_CONTENT:
      return <NewContentForm />
    case FORM_NAMES.COPY_PANELS:
      return <CopyPanelsForm />
    case FORM_NAMES.EDIT_PANEL_TITLE:
      return <EditPanelTitleForm />
    default:
      return null
  }
}

const Modal = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    isOpen,
    textHeader,
    textContent,
    additionalContent,
    callback,
    imagePreview,
    videoPreview,
    form,
    maxWidth,
    autoClose,
    hasSoundEnabled,
  } = useSelector(state => state.modal)

  return (
    <Dialog maxWidth={maxWidth} fullWidth={true} open={isOpen} onClose={() => dispatch(closeModal())}>
      <DialogTitle id="form-dialog-title">{textHeader}</DialogTitle>
      <DialogContent dividers className={imagePreview && classes.imagePreviewDialog}>
        {textContent && <DialogContentText>{textContent}</DialogContentText>}
        {additionalContent && <div>{additionalContent}</div>}
        {imagePreview && (
          <img
            className={classes.imagePreview}
            onError={e => {
              e.target.onerror = null
              e.target.src = imageNotFound
            }}
            src={`${STAGING_IMAGE_PATH_PREFIX}${imagePreview}`}
            alt="Preview"
          />
        )}
        {videoPreview && <VideoPlayer videoId={videoPreview} hasSoundEnabled={hasSoundEnabled} />}
        {form && displayForm(form)}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(closeModal())} color="primary">
          Close
        </Button>
        {(callback || form) && (
          <Button
            onClick={() => {
              callback && callback()
              form && dispatch(submit(form))
              autoClose && dispatch(closeModal())
            }}
            color="primary"
          >
            Confirm
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default Modal
