import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MaterialUICheckbox from '@material-ui/core/Checkbox'

const Checkbox = ({ input, label }) => (
  <FormControlLabel
    control={<MaterialUICheckbox checked={input.value ? true : false} color="primary" onChange={input.onChange} />}
    label={label}
  />
)

export default Checkbox
