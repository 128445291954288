import React from 'react'
import { useSelector } from 'react-redux';
import { reduxForm, Field, SubmissionError } from 'redux-form'
import { Grid, MenuItem } from '@material-ui/core'
import Input from '../../../reusable/input'
import { addPanel } from '../../page.redux'
import { PANEL_TYPE} from '../../page.type'
import { FORM_NAMES, PAGE_TYPE } from '../../../modules.constants'

const NewPanelForm = ({ handleSubmit }) => {
  const pageType = useSelector(state => state.page.pageInfos.value.pageType);
  const isCategoryPage = pageType === PAGE_TYPE.CATEGORY_PAGE;

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6}>
          <Field component={Input} autoFocus name="title" margin="dense" label="Title" fullWidth />
        </Grid>
        <Grid item xs={4}>
          <Field component={Input} select margin="dense" name="type" label="Type" fullWidth>
            {/* NOTES: 
              - we can only add a Category Panel to a Category Page 
              - we cannot add a Category Panel to a Home Page
            */}
            {!isCategoryPage && <MenuItem value={PANEL_TYPE.BANNER}>Banner</MenuItem>}
            {!isCategoryPage && <MenuItem value={PANEL_TYPE.GUEST_CAROUSEL}>Guest carousel</MenuItem>}
            {!isCategoryPage && <MenuItem value={PANEL_TYPE.CAROUSEL}>Carousel</MenuItem>}
            {!isCategoryPage && <MenuItem value={PANEL_TYPE.FULL_PANEL}>Full panel</MenuItem>}
            {!isCategoryPage && <MenuItem value={PANEL_TYPE.HALF_PANEL}>Half panel</MenuItem>}
            {!isCategoryPage && <MenuItem value={PANEL_TYPE.VIDEO}>Video</MenuItem>}
            {!isCategoryPage && <MenuItem value={PANEL_TYPE.FOOTER}>Footer</MenuItem>}
            {!isCategoryPage && <MenuItem value={PANEL_TYPE.SOCIAL_MEDIA}>Social Media</MenuItem>}
            {isCategoryPage && <MenuItem value={PANEL_TYPE.CATEGORY}>Category</MenuItem>}
          </Field>
        </Grid>
      </Grid>
    </form>
  );
}

const submit = values => {
  const error = {}
  if (!values.title) error['title'] = 'Required'
  if (!values.type) error['type'] = 'Required'
  if (Object.entries(error).length > 0) throw new SubmissionError(error)
}

export default reduxForm({
  form: FORM_NAMES.NEW_PANEL,
  onSubmit: submit,
  onSubmitSuccess: (_, dispatch) => dispatch(addPanel()),
})(NewPanelForm)
