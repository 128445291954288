import React from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: '#fff',
  },
}))

const Loader = () => {
  const classes = useStyles()
  const { isVisible } = useSelector(({ loader }) => loader)

  return (
    <Backdrop className={classes.backdrop} open={isVisible}>
      <CircularProgress color="secondary" size={80} />
    </Backdrop>
  )
}

export default Loader
