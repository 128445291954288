import { INITIAL_VALUES } from './page.type'
import { PANEL_TYPE, LOCALES } from './page.type'
import { filter, get } from 'partial.lenses'
import { checkField } from '../../utils/utils.validation'
import { switchPanelErrorFlag } from './page.redux'

/**
 * Get initial values per panel type
 *
 * @param {Object} panelValues Panel values
 */
export const getInitialValuesByPanelType = panelValues => {
  const initialValues = {
    title: panelValues.title,
    type: panelValues.type,
    ...INITIAL_VALUES[panelValues.type],
  }

  /* NOTES:
    - for now when we create a Category Panel, we assign the Panel Title as the Category Name
    - this is temporary as we will keep it or remove it depending on the users feedback
    - if users decide not to have it, it will be removed
  */
  if(panelValues.type === PANEL_TYPE.CATEGORY) initialValues.categoryName = panelValues.title;

  return initialValues;
}

/**
 * Map full panel properties
 *
 * @param {Object} panelInfos Panel infos
 * @returns {Object} Full panel infos after mapping
 */
const mapPanelInfos = panelInfos => ({
  hasEmbeddedText: panelInfos.has_embedded_text,
  panelText: panelInfos.panel_text,
  textPosition: panelInfos.text_position,
  textStyle: panelInfos.text_style,
  actionType: panelInfos.action_type,
  actionTarget: panelInfos.action_target,
  background: !!panelInfos.video_id ? 'videoId' : !!panelInfos.image_url ? 'imageUrl' : 'backgroundColor',
  backgroundColor: panelInfos.background_color,
  imageUrl: panelInfos.image_url,
  panelSize: panelInfos.panel_size
})

/**
 * Map carousel infos and its panels
 *
 * @param {number} panel_id Panel ID
 * @param {string} panel_type Panel type
 * @param {Object} panel_infos Panel infos including carousel items
 * @returns {Object} Mapped carousel infos
 */
const mapCarouselInfos = (panel_id, panel_type, panel_infos) => ({
  hasError: false,
  panelId: panel_id,
  type: panel_type,
  title: panel_infos.title,
  panels: panel_infos.carouselItems.map(carouselItem => ({
    hasError: false,
    carouselItemId: carouselItem.panel_carousel_item_id,
    title: carouselItem.title,
    videoId: carouselItem.video_id,
    ...mapPanelInfos(carouselItem),
  })),
})

/**
 * Map footer infos
 *
 * @param {number} panel_id Panel ID
 * @param {string} panel_type Panel type
 * @param {Object} panel_infos Panel infos
 * @returns {Object} Mapped footer infos
 */
const mapFooterInfos = (panel_id, panel_type, panel_infos) => ({
  hasError: false,
  panelId: panel_id,
  type: panel_type,
  title: panel_infos.title,
  footerItems: panel_infos.footerItems.map(footerItem => ({
    footerItemId: footerItem.panel_footer_item_id,
    label: footerItem.label,
    itemUrl: footerItem.item_url,
  })),
})

/**
 * Map video infos
 *
 * @param {number} panel_id Panel ID
 * @param {string} panel_type Panel type
 * @param {Object} panel_infos Panel infos
 * @returns {Object} Mapped footer infos
 */
const mapVideoInfos = (panel_id, panel_type, panel_infos) => ({
  hasError: false,
  isAltImageVisible: false,
  panelId: panel_id,
  type: panel_type,
  title: panel_infos.title,
  videoId: panel_infos.video_id,
  actionType: panel_infos.action_type,
  actionTarget: panel_infos.action_target,
  hasButton: panel_infos.has_button,
  buttonText: panel_infos.button_text,
  buttonBackgroundColor: panel_infos.button_background_color,
  buttonFontFamily: panel_infos.button_font_family,
  buttonFontColor: panel_infos.button_font_color,
  buttonFontSize: panel_infos.button_font_size,
  buttonBorderRadius: panel_infos.button_border_radius,
  buttonVerticalPadding: panel_infos.button_vertical_padding,
  buttonHorizontalPadding: panel_infos.button_horizontal_padding,
  buttonPosition: panel_infos.button_position,
  hasHeader: panel_infos.has_header,
  headerText: panel_infos.header_text,
  headerFontColor: panel_infos.header_font_color,
  headerFontSize: panel_infos.header_font_size,
  headerFontFamily: panel_infos.header_font_family,
  headerFontWeight: panel_infos.header_font_weight,
  headerPosition: panel_infos.header_position,
  hasSubHeader: panel_infos.has_sub_header,
  subHeaderText: panel_infos.sub_header_text,
  subHeaderFontColor: panel_infos.sub_header_font_color,
  subHeaderFontSize: panel_infos.sub_header_font_size,
  subHeaderFontFamily: panel_infos.sub_header_font_family,
  subHeaderFontWeight: panel_infos.sub_header_font_weight,
  subHeaderPosition: panel_infos.sub_header_position,
  hasTint: panel_infos.has_tint,
  tintColorOverlay: panel_infos.tint_color_overlay,
  tintColorOpacity: panel_infos.tint_color_opacity,
  altImageUrl: panel_infos.alt_image_url,
  hasSoundEnabled: panel_infos.has_sound_enabled,
})

export const mapPanelByType = ({ panel_type, panel_id, locale_id, ...panel_infos }) => {
  switch (panel_type) {
    case PANEL_TYPE.BANNER:
      return {
        hasError: false,
        panelId: panel_id,
        type: panel_type,
        title: panel_infos.title,
        heading: panel_infos.heading,
        subheading: panel_infos.subheading,
        guestHeader: panel_infos.guest_header,
        guestSubheader: panel_infos.guest_subheader,
        panelBannerId: panel_infos.panel_banner_id,
      }
    case PANEL_TYPE.FULL_PANEL:
      return {
        hasError: false,
        panelId: panel_id,
        type: panel_type,
        title: panel_infos.title,
        panelSize: panel_infos.panel_size,
        ...mapPanelInfos(panel_infos),
      }
    case PANEL_TYPE.HALF_PANEL:
      return {
        hasError: false,
        panelId: panel_id,
        type: panel_type,
        title: panel_infos.title,
        leftPanel: mapPanelInfos(panel_infos.left_panel),
        rightPanel: mapPanelInfos(panel_infos.right_panel),
      }
    case PANEL_TYPE.CAROUSEL:
      return mapCarouselInfos(panel_id, panel_type, panel_infos)
    case PANEL_TYPE.GUEST_CAROUSEL:
      return mapCarouselInfos(panel_id, panel_type, panel_infos)
    case PANEL_TYPE.VIDEO:
      return mapVideoInfos(panel_id, panel_type, panel_infos)
    case PANEL_TYPE.FOOTER:
      return mapFooterInfos(panel_id, panel_type, panel_infos)
    case PANEL_TYPE.CATEGORY:
      return {
        hasError: false,
        panelId: panel_id,
        type: panel_type,
        title: panel_infos.title,
        categoryId: panel_infos.category_id,
        /* NOTES:
          - adding the title to the category name as well because this field is disabled for now
          - we will remove or keep the category name field depending on the users feedback
        */
        categoryName: panel_infos.title,
      }
    case PANEL_TYPE.SOCIAL_MEDIA:
      return {
        type: panel_type,
        panelId: panel_id,
        title: panel_infos.title
      }
    default:
      return { ...panel_infos, panel_type }
  }
}

/**
 * Map panels by type for a given locale
 *
 * @param {Object} localePanels Panels for a locale
 * @returns {Object[]} Panels mapped by type
 */
const mapLocalePanels = localePanels => ({
  panels: localePanels && localePanels.panels ? localePanels.panels.map(panel => mapPanelByType(panel)) : [],
})

/**
 * Map panels for each locales
 *
 * @param {Object} localePanels Panels by locales
 * @returns {Object} Panels mapped by type for each locales
 */
export const mapLocalePanelsToPage = localePanels => ({
  [LOCALES.EN_CA]: mapLocalePanels(localePanels[LOCALES.EN_CA]),
  [LOCALES.EN_US]: mapLocalePanels(localePanels[LOCALES.EN_US]),
  [LOCALES.FR_CA]: mapLocalePanels(localePanels[LOCALES.FR_CA]),
})

/**
 * Map result received from the API to the formatting waited by the components
 *
 * @param {Object} params
 * @param {number} params.page_id Page ID
 * @param {string} params.page_type Page type
 * @param {string} params.title Title
 * @param {string} params.brand Brand
 * @param {string} params.go_live_date Go live date
 * @param {string} params.is_published Is published
 * @param {string} params.last_update Last update
 * @param {string} params.last_update_user Last update user
 * @param {Object} params.locales locales
 * @param {Object} params.rest Other properties
 * @returns {Object} Page properties
 */
export const mapResultToPageVersion = ({
  page_id,
  page_type,
  title,
  brand,
  page_version_id,
  go_live_date,
  is_published,
  last_update,
  last_update_user,
  locales,
  ...rest
}) => ({
  pageId: page_id,
  pageVersionId: page_version_id,
  pageType: page_type,
  title,
  brand,
  goLiveDate: go_live_date,
  lastUpdate: last_update,
  lastUpdateUser: last_update_user,
  ...rest,
  ...mapLocalePanelsToPage(locales),
})

/**
 * Check if a given flag is present for a specific page version ID into a page version list
 *
 * @param {number} currentVersionId Current version ID
 * @param {Object[]} versions Array of versions
 * @param {string} flag Flag name
 * @returns {boolean} True if the flag is present for a specific page version ID or null if versions is not present
 */
export const checkCurrentVersionFlag = (currentVersionId, versions, flag) =>
  versions
    ? get([filter(version => version.pageVersionId === currentVersionId), x => x && x[0] && x[0][flag]], versions)
    : null

/**
 * Loop into all versions to map page version results
 *
 * @param {Object[]} pageVersions Page versions
 * @returns {Object[]} Page versions
 */
export const mapPageVersionsToPage = pageVersions =>
  pageVersions.map(
    ({ page_version_id, title, go_live_date, is_published, last_update, last_update_user, is_online_version }) => ({
      pageVersionId: page_version_id,
      title,
      goLiveDate: go_live_date,
      isPublished: is_published,
      lastUpdate: last_update,
      lastUpdateUser: last_update_user,
      isOnlineVersion: is_online_version,
    })
  )

/**
 * Set conditional value regarding condition parameter. Returns null otherwise.
 *
 * @param {boolean} condition Condition
 * @param {any} value returned value
 */
const setConditionalValue = (condition, value) => (condition ? value : null)

/**
 * Check conditional values into panel infos
 *
 * @param {Object} panelValues Panel values
 */
const checkConditionalValues = panelValues => ({
  ...panelValues,
  imageUrl: setConditionalValue(panelValues.background === 'imageUrl', panelValues.imageUrl),
  backgroundColor: setConditionalValue(panelValues.background === 'backgroundColor', panelValues.backgroundColor),
  panelText: setConditionalValue(panelValues.hasEmbeddedText, panelValues.panelText),
  textPosition: setConditionalValue(panelValues.hasEmbeddedText, panelValues.textPosition),
  textStyle: setConditionalValue(panelValues.hasEmbeddedText, panelValues.textStyle),
})

const checkVideoConditionalValues = panelValues => ({
  ...panelValues,
  buttonText: setConditionalValue(panelValues.hasButton, panelValues.buttonText),
  buttonBackgroundColor: setConditionalValue(panelValues.hasButton, panelValues.buttonBackgroundColor),
  buttonFontFamily: setConditionalValue(panelValues.hasButton, panelValues.buttonFontFamily),
  buttonFontColor: setConditionalValue(panelValues.hasButton, panelValues.buttonFontColor),
  buttonFontSize: setConditionalValue(panelValues.hasButton, panelValues.buttonFontSize),
  buttonBorderRadius: setConditionalValue(panelValues.hasButton, panelValues.buttonBorderRadius),
  buttonVerticalPadding: setConditionalValue(panelValues.hasButton, panelValues.buttonVerticalPadding),
  buttonHorizontalPadding: setConditionalValue(panelValues.hasButton, panelValues.buttonHorizontalPadding),
  buttonPosition: setConditionalValue(panelValues.hasButton, panelValues.buttonPosition),
  headerText: setConditionalValue(panelValues.hasHeader, panelValues.headerText),
  headerFontColor: setConditionalValue(panelValues.hasHeader, panelValues.headerFontColor),
  headerFontSize: setConditionalValue(panelValues.hasHeader, panelValues.headerFontSize),
  headerFontFamily: setConditionalValue(panelValues.hasHeader, panelValues.headerFontFamily),
  headerFontWeight: setConditionalValue(panelValues.hasHeader, panelValues.headerFontWeight),
  headerPosition: setConditionalValue(panelValues.hasHeader, panelValues.headerPosition),
  subHeaderText: setConditionalValue(panelValues.hasSubHeader, panelValues.subHeaderText),
  subHeaderFontColor: setConditionalValue(panelValues.hasSubHeader, panelValues.subHeaderFontColor),
  subHeaderFontSize: setConditionalValue(panelValues.hasSubHeader, panelValues.subHeaderFontSize),
  subHeaderFontFamily: setConditionalValue(panelValues.hasSubHeader, panelValues.subHeaderFontFamily),
  subHeaderFontWeight: setConditionalValue(panelValues.hasSubHeader, panelValues.subHeaderFontWeight),
  subHeaderPosition: setConditionalValue(panelValues.hasSubHeader, panelValues.subHeaderPosition),
  tintColorOverlay: setConditionalValue(panelValues.hasTint, panelValues.tintColorOverlay),
  tintColorOpacity: setConditionalValue(panelValues.hasTint, panelValues.tintColorOpacity),
})

/**
 * Map panel values by panel type by checking conditional values regarding hasEmbeddedText and background flags
 *
 * @param {Object} params
 * @param {string} params.type Panel type
 * @param {Object} params.panelValues Panel values
 * @returns {Object} Mapped panel
 */
const mapPanelForSaving = ({ type, ...panelValues }) => {
  switch (type) {
    case PANEL_TYPE.CAROUSEL:
      return {
        ...panelValues,
        type,
        panels: panelValues.panels.map(panel => checkConditionalValues(panel)),
      }
    case PANEL_TYPE.GUEST_CAROUSEL:
      return {
        ...panelValues,
        type,
        panels: panelValues.panels.map(panel => checkConditionalValues(panel)),
      }
    case PANEL_TYPE.FULL_PANEL:
      return {
        ...panelValues,
        type,
        ...checkConditionalValues(panelValues),
      }
    case PANEL_TYPE.HALF_PANEL:
      return {
        ...panelValues,
        type,
        leftPanel: checkConditionalValues(panelValues.leftPanel),
        rightPanel: checkConditionalValues(panelValues.rightPanel),
      }
    case PANEL_TYPE.VIDEO:
      return {
        ...panelValues,
        type,
        ...checkVideoConditionalValues(panelValues),
      }
    default:
      return { ...panelValues, type }
  }
}

/**
 * Map locale values for saving
 *
 * @param {Object} localeValues Locale values
 * @returns {Object} Mapped locale values
 */
export const mapLocaleValuesForSaving = localeValues => ({
  panels: localeValues && localeValues.panels ? localeValues.panels.map(panel => mapPanelForSaving(panel)) : [],
})

/**
 * Split panel name string value into panel name prefix and panel index values
 * For example, "panels[1].panels[0]", panel name prefix is "panels[1].panels" and panel index is "0"
 *
 * @param {string} panelNameAction Panel name action
 * @returns {Object} Panel name and panel index values
 */
export const splitPanelNameAction = panelNameAction => ({
  panelName: panelNameAction.substring(0, panelNameAction.lastIndexOf('[')),
  panelIndex: panelNameAction.substring(panelNameAction.lastIndexOf('[') + 1, panelNameAction.lastIndexOf(']')),
})

/**
 * Map all locale values for saving
 *
 * @param {Object} pageValues Page values
 * @returns {Object} Mapped page values
 */
export const mapPageValuesForSave = pageValues => ({
  ...pageValues,
  [LOCALES.EN_CA]: mapLocaleValuesForSaving(pageValues[LOCALES.EN_CA]),
  [LOCALES.EN_US]: mapLocaleValuesForSaving(pageValues[LOCALES.EN_US]),
  [LOCALES.FR_CA]: mapLocaleValuesForSaving(pageValues[LOCALES.FR_CA]),
})

/**
 * Dispatch switchPanelErrorFlag redux action
 * hasError flag is true if the panel has validation errors
 *
 * @param {number} nbErrors Nb errors
 * @param {string} locale Locale
 * @param {number} panelIndex Panel index
 * @param {Function} dispatch Dispatch function
 */
const switchPanelErrorFlagIfError = (nbErrors, locale, panelIndex, dispatch) =>
  dispatch(switchPanelErrorFlag({ locale, panelIndex, hasError: nbErrors > 0 }))

// /**
//  * Dispatch switchCarouselPanelErrorFlag redux action
//  * hasError flag is true if the carousel panel has validation errors
//  *
//  * @param {number} nbErrorsBeforeValidation Nb errors before validation
//  * @param {number} nbErrorsAfterValidation Nb errors after validation
//  * @param {string} locale Locale
//  * @param {number} panelIndex Panel index
//  * @param {number} carouselPanelIndex Carousel panel index
//  * @param {Function} dispatch Dispatch function
//  */
// const switchCarouselPanelErrorFlagIfError = (
//   nbErrorsBeforeValidation,
//   nbErrorsAfterValidation,
//   locale,
//   panelIndex,
//   carouselPanelIndex,
//   dispatch
// ) =>
//   dispatch(
//     switchCarouselPanelErrorFlag({
//       locale,
//       panelIndex,
//       carouselPanelIndex,
//       hasError: nbErrorsBeforeValidation < nbErrorsAfterValidation,
//     })
//   )

/**
 * Check panel errors for each fields depending panel type
 *
 * @param {Object} panelValues Panel values
 * @param {number} panelIndex Panel index
 * @param {string} locale Locale
 * @param {Function} dispatch Dispatch function
 */
const getPanelErrors = (panelValues, panelIndex, locale, dispatch) => {
  const result = { errorList: {}, nbErrors: 0 }

  switch (panelValues.type) {
    // case PanelType.FULL_PANEL: {
    //   if (panelValues.background === 'imageUrl')
    //     checkField(result, 'imageUrl', panelValues.imageUrl, ['isValidUrl'])
    //   switchPanelErrorFlagIfError(result.nbErrors, locale, panelIndex, dispatch)
    //   break
    // }
    // case PanelType.HALF_PANEL: {
    //   if (panelValues.leftPanel.background === 'imageUrl')
    //     checkField(result, 'leftPanel.imageUrl', panelValues.leftPanel.imageUrl, ['isValidUrl'])
    //   if (panelValues.rightPanel.background === 'imageUrl')
    //     checkField(result, 'rightPanel.imageUrl', panelValues.rightPanel.imageUrl, ['isValidUrl'])
    //   switchPanelErrorFlagIfError(result.nbErrors, locale, panelIndex, dispatch)
    //   break
    // }
    // case PanelType.CAROUSEL: {
    //   panelValues.panels.forEach((carouselItemValues, carouselPanelIndex) => {
    //     const nbErrorsBeforeValidation = result.nbErrors
    //     if (carouselItemValues.background === 'imageUrl')
    //       checkField(result, `panels[${carouselPanelIndex}].imageUrl`, carouselItemValues.imageUrl, ['isValidUrl'])
    //     switchCarouselPanelErrorFlagIfError(
    //       nbErrorsBeforeValidation,
    //       result.nbErrors,
    //       locale,
    //       panelIndex,
    //       carouselPanelIndex,
    //       dispatch
    //     )
    //   })
    //   switchPanelErrorFlagIfError(result.nbErrors, locale, panelIndex, dispatch)
    //   break
    // }
    // case PanelType.GUEST_CAROUSEL: {
    //   panelValues.panels.forEach((carouselItemValues, carouselPanelIndex) => {
    //     const nbErrorsBeforeValidation = result.nbErrors
    //     if (carouselItemValues.background === 'imageUrl')
    //       checkField(result, `panels[${carouselPanelIndex}].imageUrl`, carouselItemValues.imageUrl, ['isValidUrl'])
    //     switchCarouselPanelErrorFlagIfError(
    //       nbErrorsBeforeValidation,
    //       result.nbErrors,
    //       locale,
    //       panelIndex,
    //       carouselPanelIndex,
    //       dispatch
    //     )
    //   })
    //   switchPanelErrorFlagIfError(result.nbErrors, locale, panelIndex, dispatch)
    //   break
    // }
    case PANEL_TYPE.VIDEO: {
      checkField(result, 'videoId', panelValues.videoId, ['exist', 'isNumber'])
      checkField(result, 'actionType', panelValues.actionType, ['exist'])
      if (!!panelValues.actionType) checkField(result, 'actionTarget', panelValues.actionTarget, ['exist'])
      checkField(result, 'altImageUrl', panelValues.altImageUrl, ['exist'])

      if (panelValues.hasHeader) checkField(result, 'headerText', panelValues.headerText, ['exist'])
      if (panelValues.hasHeader)
        checkField(result, 'headerFontColor', panelValues.headerFontColor, ['exist', 'isHexColor'])
      if (panelValues.hasHeader) checkField(result, 'headerFontSize', panelValues.headerFontSize, ['exist', 'isNumber'])
      if (panelValues.hasHeader) checkField(result, 'headerFontFamily', panelValues.headerFontFamily, ['exist'])
      if (panelValues.hasHeader) checkField(result, 'headerFontWeight', panelValues.headerFontWeight, ['exist'])
      if (panelValues.hasHeader) checkField(result, 'headerPosition', panelValues.headerPosition, ['exist'])
      if (panelValues.hasSubHeader) checkField(result, 'subHeaderText', panelValues.subHeaderText, ['exist'])
      if (panelValues.hasSubHeader)
        checkField(result, 'subHeaderFontColor', panelValues.subHeaderFontColor, ['exist', 'isHexColor'])
      if (panelValues.hasSubHeader)
        checkField(result, 'subHeaderFontSize', panelValues.subHeaderFontSize, ['exist', 'isNumber'])
      if (panelValues.hasSubHeader)
        checkField(result, 'subHeaderFontFamily', panelValues.subHeaderFontFamily, ['exist'])
      if (panelValues.hasSubHeader)
        checkField(result, 'subHeaderFontWeight', panelValues.subHeaderFontWeight, ['exist'])
      if (panelValues.hasSubHeader) checkField(result, 'subHeaderPosition', panelValues.subHeaderPosition, ['exist'])

      if (panelValues.hasButton) checkField(result, 'buttonText', panelValues.buttonText, ['exist'])
      if (panelValues.hasButton)
        checkField(result, 'buttonBackgroundColor', panelValues.buttonBackgroundColor, ['exist', 'isHexColor'])
      if (panelValues.hasButton) checkField(result, 'buttonFontFamily', panelValues.buttonFontFamily, ['exist'])
      if (panelValues.hasButton)
        checkField(result, 'buttonFontColor', panelValues.buttonFontColor, ['exist', 'isHexColor'])
      if (panelValues.hasButton) checkField(result, 'buttonFontSize', panelValues.buttonFontSize, ['exist', 'isNumber'])
      if (panelValues.hasButton)
        checkField(result, 'buttonBorderRadius', panelValues.buttonBorderRadius, ['exist', 'isNumber'])
      if (panelValues.hasButton)
        checkField(result, 'buttonVerticalPadding', panelValues.buttonVerticalPadding, ['exist', 'isNumber'])
      if (panelValues.hasButton)
        checkField(result, 'buttonHorizontalPadding', panelValues.buttonHorizontalPadding, ['exist', 'isNumber'])
      if (panelValues.hasButton) checkField(result, 'buttonPosition', panelValues.buttonPosition, ['exist'])
      if (panelValues.hasTint)
        checkField(result, 'tintColorOverlay', panelValues.tintColorOverlay, ['exist', 'isHexColor'])
      if (panelValues.hasTint)
        checkField(result, 'tintColorOpacity', panelValues.tintColorOpacity, ['exist', 'range_0_1'])
      switchPanelErrorFlagIfError(result.nbErrors, locale, panelIndex, dispatch)
      break
    }
    case PANEL_TYPE.FOOTER: {
      panelValues.footerItems.forEach((footerItemValues, footerItemIndex) => {
        checkField(result, `footerItems[${footerItemIndex}].itemUrl`, footerItemValues.itemUrl, ['isValidUrl'])
      })
      switchPanelErrorFlagIfError(result.nbErrors, locale, panelIndex, dispatch)
      break
    }
    case PANEL_TYPE.CATEGORY: {
      checkField(result, 'categoryId', panelValues.categoryId, ['exist'])
      switchPanelErrorFlagIfError(result.nbErrors, locale, panelIndex, dispatch)
      break
    }
    default:
      break
  }
  return result
}

/**
 * Get locale errors and returns errors list and nb error for this locale
 *
 * @param {Object} result Result object including errors list and nb error
 * @param {string} locale Locale
 * @param {Object[]} localePanels Array of panels for a locale
 * @param {Function} dispatch Dispatch function
 */
export const getLocaleErrors = (result, locale, localePanels, dispatch) => {
  let nbLocaleErrors = 0

  const panels = localePanels.map((panel, index) => {
    const { errorList, nbErrors } = getPanelErrors(panel, index, locale, dispatch)
    nbLocaleErrors = nbLocaleErrors + nbErrors
    return errorList
  })

  result.nbErrors = result.nbErrors + nbLocaleErrors
  result.errors[locale] = { panels, nbLocaleErrors }
}

export const validatePageForm = (values, dispatch) => {
  const result = { errors: {}, nbErrors: 0 }
  Object.keys(LOCALES).forEach(locale => getLocaleErrors(result, locale, values[locale].panels, dispatch))
  return result
}
