import React from 'react'
import { Snackbar, makeStyles } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { useSelector, useDispatch } from 'react-redux'
import { hideToast } from '../toast.redux'

const useStyles = makeStyles(() => ({
  snackbar: {
    bottom: '40px',
  },
  alert: {
    maxWidth: '325px',
  },
}))

const Toast = () => {
  const classes = useStyles()
  const { isVisible, message, severity } = useSelector(({ toast }) => toast)
  const dispatch = useDispatch()

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      onClose={() => dispatch(hideToast())}
      autoHideDuration={4000}
      open={isVisible}
      className={classes.snackbar}
      bodyStyle={{ height: 'auto', lineHeight: '28px', padding: 24, whiteSpace: 'pre-line' }}
    >
      <Alert
        className={classes.alert}
        elevation={6}
        variant="filled"
        onClose={() => dispatch(hideToast())}
        severity={severity}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}

export default Toast
