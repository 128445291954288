import { checkField } from '../../utils/utils.validation'

/**
 * Map result received from the API to the formatting waited by the components
 *
 * @param {Object} params
 * @param {number} params.content_id Content ID
 * @param {string} params.content_type Content type
 * @param {string} params.title Title
 * @param {string} params.brand Brand
 * @param {string} params.last_update Last update
 * @param {string} params.last_update_user Last update user
 * @param {string} params.hero_image Hero image
 * @param {string[]} params.gallery_images Gallery images
 * @returns {Object} Content properties
 */
export const mapResultToContent = ({
  content_id,
  content_type,
  title,
  brand,
  last_update,
  last_update_user,
  hero_image,
  gallery_images,
}) => ({
  contentId: content_id,
  contentType: content_type,
  title,
  brand,
  lastUpdate: last_update,
  lastUpdateUser: last_update_user,
  heroImage: {
    syteImageId: (hero_image && hero_image.syte_image_id) || '',
    imageUrl: (hero_image && hero_image.image_url) || '',
  },
  galleryImages: gallery_images.map(({ syte_image_id, image_url }) => ({
    syteImageId: syte_image_id,
    imageUrl: image_url,
  })),
})

export const validateContentForm = values => {
  const result = { errorList: {}, nbErrors: 0 }
  checkField(result, 'title', values.title, ['exist'])
  checkField(result, 'heroImage.imageUrl', values.heroImage.imageUrl, ['exist'])
  values.galleryImages.forEach((image, idx) =>
    checkField(result, `galleryImages[${idx}].imageUrl`, image.imageUrl, ['exist'])
  )

  return result
}

export const reduceGalleryImages = (galleryImages, chunkLength) =>
  galleryImages.reduce((resultArray, item, idx) => {
    const chunkIndex = Math.floor(idx / chunkLength)

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = { layoutType: chunkIndex % chunkLength, chunkImages: [] } // start a new chunk
    }

    resultArray[chunkIndex].chunkImages.push(item)

    return resultArray
  }, [])
