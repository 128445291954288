import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'

const styles = {
  formControl: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  formLabel: {
    minWidth: '250px',
  },
  formControlLabel: {
    minWidth: '125px',
  },
}

const RadioButtons = ({ label, input, values, classes, disabled }) => (
  <FormControl disabled={disabled} className={classes.formControl}>
    {label && <FormLabel className={classes.formLabel}>{label}</FormLabel>}
    <RadioGroup row onChange={input.onChange}>
      {values.map(({ value, label }) => (
        <FormControlLabel
          className={classes.formControlLabel}
          key={value}
          value={value}
          checked={input.value === value}
          control={<Radio color="primary" />}
          label={label}
        />
      ))}
    </RadioGroup>
  </FormControl>
)

export default withStyles(styles)(RadioButtons)
