import _ from 'lodash'

/**
 * Check field value for each tests given as parameters (exist, isValidUrl, etc.)
 * result object is altered if the field doesn't respect one of the tests
 *
 * @param {Object} result result object with errors list and counter
 * @param {Object} result.errorList Error list object
 * @param {number} result.nbErrors Nb errors counter
 * @param {string} fieldName Field name
 * @param {string} fieldValue Field value
 * @param {string[]} tests Array of tests
 */
export const checkField = (result, fieldName, fieldValue, tests) => {
  let hasError = false
  let i = 0
  while (i < tests.length && !hasError) {
    if (tests[i] === 'exist' && !fieldValue) {
      _.set(result.errorList, fieldName, 'Required')
      result.nbErrors++
      hasError = true
    } else if (tests[i] === 'isValidUrl' && fieldValue && !isValidURL(fieldValue)) {
      _.set(result.errorList, fieldName, 'Invalid URL')
      result.nbErrors++
      hasError = true
    } else if (tests[i] === 'isNumber' && !isNumber(fieldValue)) {
      _.set(result.errorList, fieldName, 'Must be a number')
      result.nbErrors++
      hasError = true
    } else if (tests[i] === 'isHexColor' && !isHexColor(fieldValue)) {
      _.set(result.errorList, fieldName, 'Invalid hex color')
      result.nbErrors++
      hasError = true
    } else if (tests[i] === 'range_0_1' && !isBetweenRange(fieldValue, 0, 1)) {
      _.set(result.errorList, fieldName, 'Must be between 0 and 1')
      result.nbErrors++
      hasError = true
    }
    i++
  }
}

/**
 * Test if a given url is valid (starts with "https")
 *
 * @param {string} url URL
 * @returns {boolean} URl regEx result
 */
export const isValidURL = url => new RegExp('(https://)[^s(["<,>]*.[^s[",><]*').test(url)

/**
 * Test if a given value is a number
 *
 * @param {any} value
 * @returns {boolean} Numeric check result
 */
export const isNumber = value => !isNaN(value)

export const isHexColor = value => new RegExp('^#[0-9a-f]{6}').test(value)

export const isBetweenRange = (value, min, max) => value >= min && value <= max
