import { tryP } from 'fluture'
import axios from 'axios'
import { API_URL } from '../../const'

export const getContentIO = (contentId, token) =>
  tryP(() =>
    axios({
      method: 'GET',
      url: `api/contents/${contentId}`,
      baseURL: API_URL,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )

export const updateContentIO = (contentId, data, userEmail, token) =>
  tryP(() =>
    axios({
      method: 'PUT',
      url: `api/contents/${contentId}`,
      baseURL: API_URL,
      data: { ...data, userEmail },
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )

export const deleteContentIO = (contentId, token) =>
  tryP(() =>
    axios({
      method: 'DELETE',
      url: `api/contents/${contentId}`,
      baseURL: API_URL,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )
