import { tryP } from 'fluture'
import axios from 'axios'
import { API_URL } from '../../const'

export const listPages = token =>
  tryP(() =>
    axios({
      method: 'GET',
      url: `api/pages/`,
      baseURL: API_URL,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )

export const listContent = token =>
  tryP(() =>
    axios({
      method: 'GET',
      url: `api/contents/`,
      baseURL: API_URL,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )

export const createPage = (data, userEmail, token) =>
  tryP(() =>
    axios({
      method: 'POST',
      url: `api/pages/add`,
      baseURL: API_URL,
      data: { ...data, userEmail },
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )

export const createContent = (data, userEmail, token) =>
  tryP(() =>
    axios({
      method: 'POST',
      url: `api/contents/add`,
      baseURL: API_URL,
      data: { ...data, userEmail },
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )
