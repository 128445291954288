import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import { format } from 'date-fns'

const styles = {
  input: {
    backgroundColor: 'white',
  },
}

const DatePicker = ({ meta: { submitting, error, touched }, input: { value, ...inputProps }, classes, ...others }) => {
  const onChange = date => {
    Date.parse(date) ? inputProps.onChange(format(date, 'yyyy-MM-dd HH:mm:ss')) : inputProps.onChange(null)
  }

  return (
    <KeyboardDateTimePicker
      {...inputProps}
      {...others}
      disableToolbar
      fullWidth
      variant="inline"
      inputVariant="outlined"
      format="MM/dd/yyyy, hh:mm a"
      ampm={false}
      disablePast={true}
      value={value ? new Date(value.replace(/-/g, '/')) : null}
      disabled={submitting}
      error={error && touched}
      InputProps={{
        className: classes.input,
      }}
      onChange={onChange}
    />
  )
}

export default withStyles(styles)(DatePicker)
