import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Field } from 'redux-form'
import { Grid } from '@material-ui/core'
import Input from '../../../reusable/input'

const GridItemWithPadding = withStyles({
  root: {
    padding: '16px 12px 0 12px',
  },
})(Grid)

const BannerSubForm = ({ panel }) => (
  <Grid container direction="row">
    <Grid xs={6} container direction="column">
      <GridItemWithPadding item>
        <Field component={Input} label="Guest Header" name={`${panel}.guestHeader`} />
      </GridItemWithPadding>
      <GridItemWithPadding item>
        <Field component={Input} label="Guest SubHeader" name={`${panel}.guestSubheader`} />
      </GridItemWithPadding>
    </Grid>
    <Grid xs={6} container direction="column">
      <GridItemWithPadding item>
        <Field component={Input} label="Header" name={`${panel}.heading`} />
      </GridItemWithPadding>
      <GridItemWithPadding item>
        <Field component={Input} label="SubHeader" name={`${panel}.subheading`} />
      </GridItemWithPadding>
    </Grid>
  </Grid>
)

export default BannerSubForm
