import React from 'react'
import { reduxForm, Field, SubmissionError } from 'redux-form'
import { Grid, MenuItem } from '@material-ui/core'
import Input from '../../reusable/input'
import { FORM_NAMES } from '../../modules.constants'
import { onAddPage } from '../home.redux'
import { PAGE_TYPE, BRAND } from '../../modules.constants'

const NewPageForm = ({ handleSubmit }) => (
  <form autoComplete="off" onSubmit={handleSubmit}>
    <Grid container direction="row" spacing={2}>
      <Grid item xs={4}>
        <Field component={Input} select margin="dense" name="pageType" label="Page Type" fullWidth>
          {Object.entries(PAGE_TYPE).map(([key, value]) => (
            // NOTE: using regex to replace all underscore characters (some values have more than one underscore)
            <MenuItem value={key}>{value.replace(/_/g, ' ')}</MenuItem>
          ))}
        </Field>
      </Grid>
      <Grid item xs={4}>
        <Field component={Input} select margin="dense" name="brand" label="Brand" fullWidth>
          {Object.entries(BRAND).map(([key, value]) => (
            <MenuItem value={key}>{value}</MenuItem>
          ))}
        </Field>
      </Grid>
    </Grid>
  </form>
)

const submit = values => {
  const error = {}
  if (!values.pageType) error['pageType'] = 'Required'
  if (!values.brand) error['brand'] = 'Required'
  if (Object.entries(error).length > 0) throw new SubmissionError(error)
}

export default reduxForm({
  form: FORM_NAMES.NEW_PAGE,
  onSubmit: submit,
  onSubmitSuccess: (_, dispatch) => dispatch(onAddPage()),
})(NewPageForm)
