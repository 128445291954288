import React, { useState } from 'react'
import ReactPlayerLoader from '@brightcove/react-player-loader'
import { BRIGHTCOVE_ACCOUNT_ID, BRIGHTCOVE_PLAYER_ID, BRIGHTCOVE_ASPECT_RATIO } from '../../const'
import { makeStyles } from '@material-ui/core/styles'
import { VolumeUp, VolumeOff } from '@material-ui/icons'
import { Button } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  soundButton: {
    color: 'black',
    backgroundColor: 'white',
    width: 50,
    height: 50,
    minWidth: 50,
    borderRadius: 50,
    opacity: 0.8,
    float: 'right',
    position: 'absolute',
    top: 7,
    right: 15,
    cursor: 'pointer',
  },
}))

const SoundButton = ref => {
  const classes = useStyles()
  const [soundMuted, setSoundMuted] = useState(true)

  const handleClick = ref =>
    soundMuted ? (ref.forwardRef.muted(false), setSoundMuted(false)) : (ref.forwardRef.muted(true), setSoundMuted(true))

  return (
    <Button className={classes.soundButton} onClick={() => handleClick(ref)}>
      {soundMuted ? <VolumeOff /> : <VolumeUp />}
    </Button>
  )
}

export const VideoPlayer = ({ videoId, onFailure, hasSoundEnabled }) => {
  const [buttonEl, setButtonEl] = useState(null)
  return (
    <>
      <ReactPlayerLoader
        className={'playerContainer'}
        accountId={BRIGHTCOVE_ACCOUNT_ID}
        playerId={BRIGHTCOVE_PLAYER_ID}
        videoId={videoId}
        options={{ autoplay: true, loop: true }}
        embedOptions={{
          responsive: {
            aspectRatio: BRIGHTCOVE_ASPECT_RATIO,
          },
        }}
        onSuccess={({ ref }) => {
          ref.controls(false)
          if (hasSoundEnabled) setButtonEl(<SoundButton forwardRef={ref} />)
        }}
        onFailure={onFailure}
      />
      {buttonEl}
    </>
  )
}
