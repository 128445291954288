import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemAvatar,
  ListItemText,
  Avatar,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import ViewDayIcon from '@material-ui/icons/ViewDay'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import DeleteIcon from '@material-ui/icons/Delete'
import SmartphoneIcon from '@material-ui/icons/Smartphone'
import MobileOffIcon from '@material-ui/icons/MobileOff'
import Alert from '@material-ui/lab/Alert'
import {
  hideVersions,
  onClonePageVersion,
  deletePageVersion,
  onSelectPageVersion,
  publishPageVersion,
  unpublishPageVersion,
} from '../page.redux'
import { openModal } from '../../modal/modal.redux'
import { Maybe } from '../../../utils/utils.types'
import { format } from 'date-fns'

const useStyles = makeStyles(() => ({
  dialogContent: {
    maxHeight: '375px',
    padding: '0',
  },
  listItem: {
    paddingRight: '155px',
  },
  flag: {
    marginRight: '20px',
  },
}))

export const PageVersionsModal = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { isModalVisible, list, currentVersionId } = useSelector(state => state.page.versions)

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={isModalVisible}
      scroll="paper"
      onClose={() => dispatch(hideVersions())}
    >
      <DialogTitle id="form-dialog-title">Page versions</DialogTitle>
      <DialogContent className={classes.dialogContent} dividers>
        <List>
          {Maybe.JUST.is(list) &&
            list.value.map(version => {
              const isCurrentVersionId = version.pageVersionId === currentVersionId

              return (
                <ListItem
                  key={version.pageVersionId}
                  onClick={() => !isCurrentVersionId && dispatch(onSelectPageVersion(version.pageVersionId))}
                  className={classes.listItem}
                  selected={isCurrentVersionId}
                  button={!isCurrentVersionId}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <ViewDayIcon color={isCurrentVersionId ? 'action' : 'inherit'} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={version.title || 'No title'}
                    secondary={
                      version.goLiveDate
                        ? `Go Live Date: ${format(
                            new Date(version.goLiveDate.replace(/-/g, '/')),
                            'MM/dd/yyyy, hh:mm a'
                          )}`
                        : 'No Go Live Date'
                    }
                  />
                  {version.isOnlineVersion && (
                    <Alert className={classes.flag} variant="filled">
                      ONLINE VERSION
                    </Alert>
                  )}
                  {version.isPublished && (
                    <Alert icon={false} className={classes.flag} variant="outlined">
                      PUBLISHED
                    </Alert>
                  )}
                  <ListItemSecondaryAction>
                    {version.isPublished ? (
                      <Tooltip title="Unpublish">
                        <IconButton onClick={() => dispatch(unpublishPageVersion(version.pageVersionId))}>
                          <MobileOffIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Publish">
                        <IconButton onClick={() => dispatch(publishPageVersion(version.pageVersionId))}>
                          <SmartphoneIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Clone version">
                      <IconButton onClick={() => dispatch(onClonePageVersion(version.pageVersionId))}>
                        <FileCopyIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete version">
                      <IconButton
                        onClick={() =>
                          dispatch(
                            openModal({
                              textHeader: 'Delete version',
                              textContent: 'Are you sure you want to delete this version?',
                              callback: () => dispatch(deletePageVersion(version.pageVersionId)),
                              autoClose: true,
                            })
                          )
                        }
                        disabled={isCurrentVersionId || version.isOnlineVersion}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(hideVersions())} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
