import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { getFormValues } from 'redux-form'
import { useSelector } from 'react-redux'
import Container from '@material-ui/core/Container'
import placeholder from '../../../../assets/images/phone-placeholder.png'
import { FORM_NAMES } from '../../../modules.constants'
import { PANEL_TYPE } from '../../page.type'
import {
  fullPanelPreview,
  carouselPreview,
  categoryPreview,
  socialPreview
} from './previewPanels'

const useStyles = makeStyles(() => ({
  container: {
    width: '386px',
    padding: '0',
  },
  placeholder: {
    backgroundImage: `url(${placeholder})`,
    padding: '88px 19px 87px 19px',
    width: '386px',
    height: '782px',
  },
  preview: {
    overflowY: 'auto',
    backgroundColor: '#fff',
    width: '100%',
    height: '100%',
    borderRadius: '7px',
  },
  header: {
    display: 'block',
    width: '100%',
  },
  banner: ({ brand }) => ({
    height: '60px',
    width: '100%',
    backgroundColor: brand === 'GRG' ? '#F0378B' : '#000',
    color: '#fff',
    textAlign: 'center',
    padding: '15px 5px',
    fontSize: '12px',
    '&>p': {
      margin: '0',
    },
  }),
  footer: ({ brand }) => ({
    minHeight: '60px',
    width: '100%',
    borderTop: '1px solid #FFF',
    backgroundColor: brand === 'GRG' ? '#FFF' : '#000',
    color: brand === 'GRG' ? '#444' : '#FFF',
    padding: '15px 0',
    textAlign: 'center',
    fontSize: '12px',
  }),
  carousel: {
    width: '100%',
    height: '86%',
    position: 'relative',
    '&>div': {
      position: 'absolute !important',
      top: '0',
    },
  },
  carouselPanel: {
    display: 'block',
    width: '100%',
    height: '100%',
  },
  carouselPlaceholder: {
    verticalAlign: 'bottom',
    width: '100%',
  },
  carouselItem: {
    backgroundColor: '#fd9f57',
    height: '90%',
  },
  fullPanel: ({ size }) => ({
    position: 'relative',
    paddingTop: '1px',
    fontSize: '0',
  }),
  fullPanelImg: {
    verticalAlign: 'bottom',
    width: '100%',
    height: '90%',
  },
  halfPanelItem: {
    position: 'relative',
    display: 'inline-block',
    width: '50%',
    '&>img': {
      width: 'calc(100% - 2px)',
      marginRight: '1px',
    },
    '&>div': {
      width: 'calc(100% - 2px)',
      marginRight: '1px',
    },
  },
  categoryItem: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    height: 50,
    borderBottom: '0.3px solid gray',
    fontSize: 12,
    fontWeight: '600',
    fontFamily: 'NeueMontreal-Medium',
    color: '#000',
    letterSpacing: 1,
    textTransform: 'uppercase',
    paddingLeft: 25,
  },
}))

export const Preview = () => {
  const { localePreview, isGuestView } = useSelector(state => state.page.preview)
  const formValues = useSelector(state => getFormValues(FORM_NAMES.PAGE)(state))
  const brand = formValues && formValues.brand
  const localePanels = formValues ? formValues[localePreview].panels : []
  // Version 2 of CMS always renders the Carousel first
  const panelsCMSv2 = localePanels.sort((panel) => panel.type === PANEL_TYPE.CAROUSEL ? -1 : 0)
  const classes = useStyles({ brand })

  return (
    <Container className={classes.container}>
      <div className={classes.placeholder}>
        <div className={classes.preview}>
          {panelsCMSv2.map((panel, index) => {
            switch (panel.type) {
              case PANEL_TYPE.CAROUSEL:
                return carouselPreview(panel, classes, localePreview)
              case PANEL_TYPE.FULL_PANEL:
                return fullPanelPreview(panel, classes)
              case PANEL_TYPE.SOCIAL_MEDIA:
                  return socialPreview(panel, classes)
              case PANEL_TYPE.CATEGORY:
                return categoryPreview(panel, classes)
              default:
                return null
            }
          })}
        </div>
      </div>
    </Container>
  )
}
