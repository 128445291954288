import { createAction, handleActions } from 'redux-actions'

export const displayToast = createAction('TOAST_DISPLAY_TOAST')
export const hideToast = createAction('TOAST_HIDE_TOAST')

export const SEVERITY = {
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
}

export const INITIAL_STATE = {
  isVisible: false,
  message: null,
  severity: SEVERITY.INFO,
}

export const toastReducer = handleActions(
  {
    [displayToast]: (state, { payload }) => ({
      ...state,
      ...payload,
      isVisible: true,
    }),
    [hideToast]: state => ({
      ...state,
      isVisible: false,
    }),
  },
  INITIAL_STATE
)
