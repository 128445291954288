export const LOCALES = {
  EN_CA: 'EN_CA',
  EN_US: 'EN_US',
  FR_CA: 'FR_CA',
}

export const PANEL_TYPE = {
  BANNER: 'BANNER',
  CAROUSEL: 'CAROUSEL',
  GUEST_CAROUSEL: 'GUEST_CAROUSEL',
  FULL_PANEL: 'FULL_PANEL',
  HALF_PANEL: 'HALF_PANEL',
  VIDEO: 'VIDEO',
  FOOTER: 'FOOTER',
  CATEGORY: 'CATEGORY',
  SOCIAL_MEDIA: 'SOCIAL_MEDIA'
}

export const PanelAction = {
  PRODUCT: 'PRODUCT',
  CATEGORY: 'CATEGORY',
  INTERNAL_VIEW: 'INTERNAL_VIEW',
  EXTERNAL_VIEW: 'EXTERNAL_VIEW',
}

export const PANEL_SIZE = {
  EXTRA_SMALL: 'EXTRA_SMALL',
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE',
}

export const INTERNAL_VIEW = {
  GIFT_CARD: 'Giftcard',
  LOYALTY: 'LoyaltyScreen',
  COUPON: 'CouponScreen',
  STORE_FINDER: 'StoreFinderScreen',
  MY_CLOSET: 'MyClosetScreen',
}

export const CAROUSSEL_ITEM_INTIAL_VALUES = {
  hasError: false,
  title: 'Carousel panel',
  background: 'imageUrl',
  hasEmbeddedText: false,
  videoId: null
}

export const INITIAL_VALUES = {
  [PANEL_TYPE.BANNER]: { hasError: false },
  [PANEL_TYPE.CAROUSEL]: {
    panels: [CAROUSSEL_ITEM_INTIAL_VALUES, CAROUSSEL_ITEM_INTIAL_VALUES],
  },
  [PANEL_TYPE.GUEST_CAROUSEL]: {
    panels: [CAROUSSEL_ITEM_INTIAL_VALUES, CAROUSSEL_ITEM_INTIAL_VALUES],
  },
  [PANEL_TYPE.FULL_PANEL]: {
    hasError: false,
    background: 'imageUrl',
    hasEmbeddedText: false,
  },
  [PANEL_TYPE.HALF_PANEL]: {
    hasError: false,
    leftPanel: {
      background: 'imageUrl',
      hasEmbeddedText: false,
    },
    rightPanel: {
      background: 'imageUrl',
      hasEmbeddedText: false,
    },
  },
  [PANEL_TYPE.VIDEO]: {
    hasError: false,
    hasButton: false,
    hasHeader: false,
    hasSubHeader: false,
    hasTint: false,
    hasSoundEnabled: false,
  },
  [PANEL_TYPE.CATEGORY]: {
    hasError: false,
  },
  [PANEL_TYPE.FOOTER]: {
    footerItems: [{}, {}, {}],
  },
}
