import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton, Tooltip, Menu, MenuItem, ListItemIcon } from '@material-ui/core'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import EditIcon from '@material-ui/icons/Edit'

const useStyles = makeStyles(() => ({
  iconGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexBasis: '25%',
  },
  iconDiv: {
    width: '48px',
  },
}))

/**
 * Display panel buttons
 *
 * @param {Object} params
 * @param {number} params.index Panel index
 * @param {Function} params.onSwapUp On swap up callback
 * @param {Function} params.onSwapDown On swap down callback
 * @param {Function=} params.onRemove On remove callback
 * @param {Function=} params.onAdd On add callback
 * @param {Function=} params.onEditPanelTitle On edit panel title callback
 * @param {number} params.panelCount Panel Count
 * @param {number=} params.panelLimit Panel limit
 */
const PanelButtons = ({ index, onSwapUp, onSwapDown, onRemove, onAdd, onEditPanelTitle, panelCount, panelLimit }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = event => setAnchorEl(event.currentTarget)

  const handleClose = () => setAnchorEl(null)

  if (!panelLimit) panelLimit = Infinity

  return (
    <div className={classes.iconGroup}>
      <div className={classes.iconDiv}>
        {onSwapDown && index !== panelCount - 1 && (
          <Tooltip title="Move down">
            <IconButton
              onClick={event => {
                event.stopPropagation()
                onSwapDown()
              }}
            >
              <ArrowDownwardIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div className={classes.iconDiv}>
        {onSwapUp && index !== 0 && (
          <Tooltip title="Move up">
            <IconButton
              onClick={event => {
                event.stopPropagation()
                onSwapUp()
              }}
            >
              <ArrowUpwardIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      {(onEditPanelTitle || onAdd || onRemove) && (
        <IconButton
          onClick={event => {
            event.stopPropagation()
            handleClick(event)
          }}
        >
          <MoreVertIcon />
        </IconButton>
      )}
      {(onEditPanelTitle || onAdd || onRemove) && (
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={event => {
            event.stopPropagation()
            handleClose()
          }}
          PaperProps={{ style: { minWidth: '225px' } }}
        >
          {onEditPanelTitle && (
            <MenuItem
              onClick={event => {
                event.stopPropagation()
                onEditPanelTitle.callback()
                handleClose()
              }}
            >
              <ListItemIcon>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              {onEditPanelTitle.label}
            </MenuItem>
          )}
          {onAdd && panelCount < panelLimit && (
            <MenuItem
              onClick={event => {
                event.stopPropagation()
                onAdd.callback()
                handleClose()
              }}
            >
              <ListItemIcon>
                <AddCircleOutlineIcon fontSize="small" />
              </ListItemIcon>
              {onAdd.label}
            </MenuItem>
          )}
          {onRemove && panelCount > 1 && (
            <MenuItem
              onClick={event => {
                event.stopPropagation()
                onRemove.callback()
                handleClose()
              }}
            >
              <ListItemIcon>
                <DeleteOutlineIcon fontSize="small" />
              </ListItemIcon>
              {onRemove.label}
            </MenuItem>
          )}
        </Menu>
      )}
    </div>
  )
}

export default PanelButtons
