import React from 'react'
import PanelButtons from './panelButtons'
import { Field } from 'redux-form'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography } from '@material-ui/core'
import { openModal } from '../../../modal/modal.redux'
import { FORM_NAMES } from '../../../modules.constants'
import FullPanelSubForm from './fullPanelSubForm'
import ReadOnlyField from '../../../reusable/readOnlyField'
import { selectPanelNameAction } from '../../page.redux'

const useStyles = makeStyles(theme => ({
  root: { width: '100%' },
  expansionPanel: {
    '&.Mui-expanded': { margin: 0 },
  },
  panelSummary: {
    backgroundColor: '#f5f5f5',
    '&.Mui-expanded': {
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
      '&>div': { margin: '12px 0' },
    },
    '&>div': { alignItems: 'center' },
  },
  panelSummaryError: {
    border: '1px solid #e65100 !important',
    backgroundColor: '#ffcfb5 !important',
    '&.Mui-expanded': {
      borderBottom: `2px solid #e65100 !important`,
    },
  },
  panelTitle: {
    flexBasis: '75%',
    paddingLeft: '1rem',
    flexShrink: 0,
  },
  panelDetails: {
    backgroundColor: '#f7f7f7',
    padding: '0 0 16px 0',
  },
}))

const CarouselSubForm = ({ fields, locale , panelType}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  return (
    <div className={classes.root}>
      {fields.map((carouselPanel, index) => (
        <ExpansionPanel
          TransitionProps={{ unmountOnExit: true }}
          className={classes.expansionPanel}
          key={`${fields.get(index).title}-${index}`}
        >
          <ExpansionPanelSummary
            className={`${classes.panelSummary} ${fields.get(index).hasError && classes.panelSummaryError}`}
          >
            <Typography className={classes.panelTitle}>
              <Field component={ReadOnlyField} name={`${carouselPanel}.title`} />
            </Typography>
            <PanelButtons
              index={index}
              onSwapUp={() => fields.swap(index - 1, index)}
              onSwapDown={() => fields.swap(index, index + 1)}
              onRemove={{
                callback: () =>
                  dispatch(
                    openModal({
                      textHeader: 'Delete carousel panel',
                      textContent: 'Are you sure you want to delete this carousel panel?',
                      callback: () => fields.remove(index),
                      autoClose: true,
                    })
                  ),
                label: 'Delete carousel panel',
              }}
              onAdd={{
                callback: () => {
                  dispatch(selectPanelNameAction(carouselPanel))
                  dispatch(
                    openModal({
                      textHeader: 'Add new carousel panel',
                      form: FORM_NAMES.NEW_CAROUSEL_PANEL,
                      autoClose: false,
                    })
                  )
                },
                label: 'Add new carousel panel',
              }}
              onEditPanelTitle={{
                callback: () => {
                  dispatch(selectPanelNameAction(carouselPanel))
                  dispatch(
                    openModal({
                      textHeader: 'Edit carousel panel title',
                      form: FORM_NAMES.EDIT_PANEL_TITLE,
                      autoClose: false,
                    })
                  )
                },
                label: 'Edit carousel panel title',
              }}
              panelCount={fields.length}
            />
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.panelDetails}>
            <FullPanelSubForm locale={locale} panel={carouselPanel} panelType={panelType}/>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </div>
  )
}

export default CarouselSubForm
