import React from 'react'
import _ from 'lodash'
import { reduxForm, Field, SubmissionError, getFormValues } from 'redux-form'
import { connect } from 'react-redux'
import { Grid } from '@material-ui/core'
import Input from '../../../reusable/input'
import { editPanelTitle } from '../../page.redux'
import { FORM_NAMES } from '../../../modules.constants'
import { get } from 'partial.lenses'

const Form = ({ handleSubmit }) => (
  <form autoComplete="off" onSubmit={handleSubmit}>
    <Grid container direction="row" spacing={2}>
      <Grid item xs={6}>
        <Field component={Input} autoFocus name="title" margin="dense" label="Title" fullWidth />
      </Grid>
    </Grid>
  </form>
)

const submit = values => {
  const error = {}
  if (!values.title) error['title'] = 'Required'
  if (Object.entries(error).length > 0) throw new SubmissionError(error)
}

const EditPanelTitleForm = reduxForm({
  form: FORM_NAMES.EDIT_PANEL_TITLE,
  onSubmit: submit,
  onSubmitSuccess: (_, dispatch) => dispatch(editPanelTitle()),
})(Form)

export default connect(store => {
  const pageFormValues = getFormValues(FORM_NAMES.PAGE)(store)
  const selectedLocale = get(['page', 'selectedLocale'], store)
  const panelNameAction = get(['page', 'panelNameAction'], store)

  return {
    initialValues: { title: _.get(pageFormValues, `${selectedLocale}.${panelNameAction}.title`) },
  }
})(EditPanelTitleForm)
