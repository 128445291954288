import React from 'react'
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    container: {
      marginBottom: '10px',
      height: '50px',
      alignItems: 'center',
    },
  }))

const SocialMediaPanel = () => {
    const classes = useStyles();

    return (
        <Grid container direction="column" justify="center" spacing={2} className={classes.container}>
            "JUST A PLACEHOLDER FOR NOW :)"
        </Grid>
    )
}

export default SocialMediaPanel;