import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Field } from 'redux-form'
import { useSelector } from 'react-redux'
import { Grid, Typography, Divider } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import Input from '../../reusable/input'
import DatePicker from '../../reusable/datePicker'
import { BRAND } from '../../modules.constants'

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: '10px',
    height: '50px',
    alignItems: 'center',
  },
  pageCategory: {
    flexGrow: 1,
    fontSize: '18px',
    marginLeft: '5px',
  },
  divider: {
    marginBottom: '25px',
  },
  flag: {
    marginLeft: '15px',
  },
}))

export const GeneralInfos = () => {
  const classes = useStyles()
  const {
    pageInfos: { brand, pageType },
    isOnlineVersion,
    isPublishedVersion,
  } = useSelector(state => ({
    pageInfos: state.page.pageInfos.cata({
      JUST: value => value,
      NOTHING: () => '',
    }),
    isOnlineVersion: state.page.isOnlineVersion,
    isPublishedVersion: state.page.isPublishedVersion,
  }))

  return pageType ? (
    <Grid container direction="column" justify="center" spacing={2}>
      <Grid item xs={12}>
        <Grid container direction="row" justify="space-between" className={classes.header} spacing={2}>
          <Typography className={classes.pageCategory} component="span">
            {/* NOTE: using regex to replace all underscore characters (some values have more than one underscore) */}
            {pageType && pageType.replace(/_/g, ' ')} {BRAND[brand] && BRAND[brand].toUpperCase()}
          </Typography>
          {isPublishedVersion && (
            <Alert className={classes.flag} icon={false} variant="outlined">
              PUBLISHED
            </Alert>
          )}
          {isOnlineVersion && (
            <Alert className={classes.flag} variant="filled">
              ONLINE VERSION
            </Alert>
          )}
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid item xs={6}>
        <Field component={Input} label="Title" name="title" />
      </Grid>
      <Grid item xs={6}>
        <Field component={DatePicker} label="Go Live Date" name="goLiveDate" />
      </Grid>
    </Grid>
  ) : null
}
