import React from 'react'
import { reduxForm, Field, SubmissionError } from 'redux-form'
import { connect, useSelector } from 'react-redux'
import { Box, Grid, Typography, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { BRAND, FORM_NAMES } from '../../modules.constants'
import Input from '../../reusable/input'
import { SyteGalleryForm } from './syteGallery/syteGalleryForm'
import { onSaveContent } from '../content.redux'
import { validateContentForm } from '../content.logic'
import { displayToast, SEVERITY } from '../../toast/toast.redux'

const useStyles = makeStyles(() => ({
  box: {
    paddingBottom: '88px',
  },
  header: {
    marginBottom: '10px',
    height: '50px',
    alignItems: 'center',
  },
  contentCategory: {
    flexGrow: 1,
    fontSize: '18px',
    marginLeft: '5px',
  },
  divider: {
    margin: '15px 0 20px 0',
  },
}))

const Form = ({ handleSubmit }) => {
  const classes = useStyles()
  const {
    contentInfos: { brand, contentType },
  } = useSelector(state => ({
    contentInfos: state.content.contentInfos.cata({
      JUST: value => value,
      NOTHING: () => '',
    }),
  }))

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Box p={3} className={classes.box}>
        <Grid container direction="column" justify="center" spacing={2}>
          <Grid item xs={12} className={classes.header}>
            <Typography className={classes.contentCategory} component="span">
              {contentType && contentType.replace('_', ' ')} {BRAND[brand] && BRAND[brand].toUpperCase()}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Field component={Input} label="Title" name="title" />
          </Grid>
          <Divider className={classes.divider} />
          {contentType === 'SYTE_GALLERY' && <SyteGalleryForm />}
        </Grid>
      </Box>
    </form>
  )
}

const ContentForm = reduxForm({
  form: FORM_NAMES.CONTENT,
  enableReinitialize: true,
  destroyOnUnmount: false,
  onSubmit: values => {
    const { errorList, nbErrors } = validateContentForm(values)
    if (nbErrors > 0) throw new SubmissionError({ ...errorList, nbErrors })
  },
  onSubmitSuccess: (_, dispatch) => dispatch(onSaveContent()),
  onSubmitFail: (errors, dispatch) =>
    dispatch(
      displayToast({
        message: `${errors.nbErrors} validation error${errors.nbErrors > 1 ? 's have' : ' has'} been found.`,
        severity: SEVERITY.ERROR,
      })
    ),
})(Form)

export default connect(({ content: { contentInfos } }) => ({
  initialValues: contentInfos.cata({
    JUST: value => value,
    NOTHING: () => {},
  }),
}))(ContentForm)
