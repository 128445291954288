import React from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { FieldArray, Field } from 'redux-form'
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography } from '@material-ui/core'
import PanelButtons from './panelButtons'
import FullPanelSubForm from './fullPanelSubForm'
import VideoPanelSubForm from './videoPanelSubForm'
import HalfPanelSubForm from './halftPanelSubForm'
import BannerSubForm from './bannerSubForm'
import CarouselSubForm from './carouselSubForm'
import FooterSubForm from './footerSubForm'
import CategorySubForm from './categorySubForm';
import { openModal } from '../../../modal/modal.redux'
import { PANEL_TYPE } from '../../page.type'
import { FORM_NAMES } from '../../../modules.constants'
import ReadOnlyField from '../../../reusable/readOnlyField'
import { selectPanelNameAction } from '../../page.redux'
import SocialMediaPanel from './socialMediaPanel'

const useStyles = makeStyles(theme => ({
  panelSummary: {
    '&.Mui-expanded': {
      backgroundColor: '#e5e5e5',
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      '&>div': { margin: '12px 0' },
    },
    '&:not(.Mui-expanded):hover': {
      backgroundColor: '#f5f5f5',
    },
    '&>div': { alignItems: 'center' },
  },
  panelSummaryError: {
    border: '1px solid #e65100 !important',
    backgroundColor: '#ffcfb5 !important',
    '&.Mui-expanded': {
      borderBottom: `2px solid #e65100 !important`,
    },
  },
  panelTitle: {
    flexBasis: '35%',
    flexShrink: '0',
  },
  panelType: {
    flexBasis: '40%',
    flexShrink: '0',
  },
}))

const PanelDetails = styled(ExpansionPanelDetails)`
  padding: ${({ paneltype }) => (paneltype === 'CAROUSEL' || paneltype === 'GUEST_CAROUSEL' ? '0' : '0 0 16px 0')};
`

/**
 * Render panel form for each panel type
 *
 * @param {string} panelType Panel type (FULL_PANEL, CAROUSEL, etc.)
 * @param {string} panel Panel name
 * @param {string} locale Locale value
 */
const renderPanelDetailsByType = (panelType, panel, locale) => {
  switch (panelType) {
    case PANEL_TYPE.BANNER:
      return <BannerSubForm panel={panel} />
    case PANEL_TYPE.CAROUSEL:
      return <FieldArray name={`${panel}.panels`} locale={locale} component={CarouselSubForm} panelType={panelType}/>
    case PANEL_TYPE.GUEST_CAROUSEL:
      return <FieldArray name={`${panel}.panels`} locale={locale} component={CarouselSubForm} />
    case PANEL_TYPE.FULL_PANEL:
      return <FullPanelSubForm locale={locale} panel={panel} panelType={panelType}/>
    case PANEL_TYPE.HALF_PANEL:
      return <HalfPanelSubForm locale={locale} panel={panel} />
    case PANEL_TYPE.VIDEO:
      return <VideoPanelSubForm locale={locale} panel={panel} />
    case PANEL_TYPE.FOOTER:
      return <FieldArray name={`${panel}.footerItems`} component={FooterSubForm} />
    case PANEL_TYPE.CATEGORY:
      return <CategorySubForm panel={panel} />
    case PANEL_TYPE.SOCIAL_MEDIA:
      return <SocialMediaPanel panel={panel}/>
    default:
      return null
  }
}

export const PanelItems = ({ fields, locale }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  return fields.map((panel, index) => (
    <ExpansionPanel TransitionProps={{ unmountOnExit: true }} key={`${fields.get(index).title}-${index}`}>
      <ExpansionPanelSummary
        className={`${classes.panelSummary} ${fields.get(index).hasError && classes.panelSummaryError}`}
      >
        <Typography className={classes.panelTitle}>
          <Field component={ReadOnlyField} name={`${panel}.title`} />
        </Typography>
        <Typography className={classes.panelType} color="textSecondary">
          {fields.get(index).type.replace('_', ' ')}
        </Typography>
        <PanelButtons
          index={index}
          onSwapUp={() => fields.swap(index - 1, index)}
          onSwapDown={() => fields.swap(index, index + 1)}
          onRemove={{
            callback: () =>
              dispatch(
                openModal({
                  textHeader: 'Delete panel',
                  textContent: 'Are you sure you want to delete this panel?',
                  callback: () => fields.remove(index),
                  autoClose: true,
                })
              ),
            label: 'Delete panel',
          }}
          onAdd={{
            callback: () => {
              dispatch(selectPanelNameAction(panel))
              dispatch(
                openModal({
                  textHeader: 'Add new panel',
                  textContent: 'What kind of panel you want to add?',
                  form: FORM_NAMES.NEW_PANEL,
                  autoClose: false,
                })
              )
            },
            label: 'Add new panel',
          }}
          onEditPanelTitle={{
            callback: () => {
              dispatch(selectPanelNameAction(panel))
              dispatch(
                openModal({
                  textHeader: 'Edit panel title',
                  form: FORM_NAMES.EDIT_PANEL_TITLE,
                  autoClose: false,
                })
              )
            },
            label: 'Edit panel title',
          }}
          panelCount={fields.length}
        />
      </ExpansionPanelSummary>
      <PanelDetails paneltype={fields.get(index).type}>
        {renderPanelDetailsByType(fields.get(index).type, panel, locale)}
      </PanelDetails>
    </ExpansionPanel>
  ))
}
