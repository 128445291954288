import { createAction, handleActions } from 'redux-actions'
import { Maybe } from '../../utils/utils.types'

export const homeMount = createAction('HOME_HOME_MOUNT')
export const fetchHomeInfos = createAction('HOME_FETCH_HOME_INFOS')
export const loadPageList = createAction('HOME_LOAD_PAGE_LIST')
export const loadContentList = createAction('HOME_LOAD_CONTENT_LIST')
export const addPage = createAction('HOME_ADD_PAGE')
export const onAddPage = createAction('HOME_ON_ADD_PAGE')
export const addContent = createAction('HOME_ADD_CONTENT')
export const onAddContent = createAction('HOME_ON_ADD_CONTENT')

export const INITIAL_STATE = {
  pageList: Maybe.NOTHING,
  contentList: Maybe.NOTHING,
}

export const homeReducer = handleActions(
  {
    [homeMount]: () => INITIAL_STATE,
    [loadPageList]: (state, { payload }) => ({
      ...state,
      pageList: Maybe.JUST(payload),
    }),
    [loadContentList]: (state, { payload }) => ({
      ...state,
      contentList: Maybe.JUST(payload),
    }),
  },
  INITIAL_STATE
)
