import React from 'react'
import _ from 'lodash'
import { getFormValues, Field } from 'redux-form'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, MenuItem, InputAdornment } from '@material-ui/core'
import { usePanelStyles, renderActionTarget, ColorPickerField, VideoIdField } from './reusable'
import Input from '../../../reusable/input'
import Checkbox from '../../../reusable/checkbox'
import { PanelAction } from '../../page.type'
import { reinitField } from '../../page.redux'
import { FORM_NAMES } from '../../../modules.constants'
import { MOBILE_APP_FONTS } from '../../../../const'
import ImageUrlField from '../../../reusable/imageUrlField'

const VideoPanelSubForm = ({ locale, panel }) => {
  const pageFormValues = useSelector(state => getFormValues(FORM_NAMES.PAGE)(state))
  const panelValues = _.get(pageFormValues, `${locale}.${panel}`)
  const classes = usePanelStyles()
  const dispatch = useDispatch()

  return (
    <Grid container direction="row">
      <Grid xs={6} item>
        <VideoIdField
          panel={panel}
          videoId={panelValues.videoId}
          label="Video ID"
          fieldName="videoId"
          hasSoundEnabled={panelValues.hasSoundEnabled}
        />
        <Grid className={classes.gridItemWithPadding} item>
          <Field
            component={Input}
            onChange={() => dispatch(reinitField({ panel, field: 'actionTarget' }))}
            select
            label="Action"
            name={`${panel}.actionType`}
          >
            <MenuItem value={PanelAction.PRODUCT}>Product</MenuItem>
            <MenuItem value={PanelAction.CATEGORY}>Category</MenuItem>
            <MenuItem value={PanelAction.INTERNAL_VIEW}>Internal view</MenuItem>
            <MenuItem value={PanelAction.EXTERNAL_VIEW}>External view</MenuItem>
          </Field>
        </Grid>
        {renderActionTarget(panelValues.actionType, panel, classes)}
        <Grid className={classes.gridItemWithPadding} container justify="space-between">
          <ImageUrlField
            label="Alt image URL"
            fieldName={`${panel}.altImageUrl`}
            imageUrlValue={panelValues.altImageUrl}
          />
        </Grid>
      </Grid>
      <Grid xs={6} item>
        <Grid className={classes.gridItemWithPadding} item>
          <Field
            component={Checkbox}
            onChange={() =>
              !panelValues.headerFontColor &&
              dispatch(
                reinitField({
                  panel,
                  field: 'headerFontColor',
                  value: '#000000',
                })
              )
            }
            label="Contains header?"
            name={`${panel}.hasHeader`}
          />
        </Grid>
        {panelValues.hasHeader && <HeaderForm panel={panel} labelPrefix="Header" fieldNamePrefix="header" />}
        <Grid className={classes.gridItemWithPadding} item>
          <Field
            component={Checkbox}
            onChange={() =>
              !panelValues.subHeaderFontColor &&
              dispatch(
                reinitField({
                  panel,
                  field: 'subHeaderFontColor',
                  value: '#000000',
                })
              )
            }
            label="Contains sub header?"
            name={`${panel}.hasSubHeader`}
          />
        </Grid>
        {panelValues.hasSubHeader && <HeaderForm panel={panel} labelPrefix="Sub Header" fieldNamePrefix="subHeader" />}
        <Grid className={classes.gridItemWithPadding} item>
          <Field
            component={Checkbox}
            onChange={() => {
              !panelValues.buttonBackgroundColor &&
                dispatch(
                  reinitField({
                    panel,
                    field: 'buttonBackgroundColor',
                    value: '#000000',
                  })
                )
              !panelValues.buttonFontColor &&
                dispatch(
                  reinitField({
                    panel,
                    field: 'buttonFontColor',
                    value: '#000000',
                  })
                )
            }}
            label="Contains a button?"
            name={`${panel}.hasButton`}
          />
        </Grid>
        {panelValues.hasButton && <ButtonSubForm panel={panel} />}
        <Grid className={classes.gridItemWithPadding} item>
          <Field component={Checkbox} label="Enable sound" name={`${panel}.hasSoundEnabled`} />
        </Grid>
        <Grid className={classes.gridItemWithPadding} item>
          <Field
            component={Checkbox}
            onChange={() =>
              !panelValues.tintColorOverlay &&
              dispatch(
                reinitField({
                  panel,
                  field: 'tintColorOverlay',
                  value: '#000000',
                })
              )
            }
            label="Contains a tint overlay?"
            name={`${panel}.hasTint`}
          />
        </Grid>
        {panelValues.hasTint && <TintSubForm panel={panel} />}
      </Grid>
    </Grid>
  )
}

const HeaderForm = ({ panel, labelPrefix, fieldNamePrefix }) => {
  const classes = usePanelStyles()

  return (
    <>
      <Grid className={classes.gridItemWithPadding} item>
        <Field component={Input} label={`${labelPrefix} Text`} name={`${panel}.${fieldNamePrefix}Text`} />
      </Grid>
      <ColorPickerField panel={panel} label={`${labelPrefix} Font Color`} fieldName={`${fieldNamePrefix}FontColor`} />
      <Grid className={classes.gridItemWithPadding} container justify="space-between">
        <Grid className={classes.subGridItem} item xs={6}>
          <Field
            component={Input}
            label={`${labelPrefix} Font Size`}
            InputProps={{
              endAdornment: <InputAdornment position="end">pixels</InputAdornment>,
            }}
            name={`${panel}.${fieldNamePrefix}FontSize`}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={Input}
            select
            label={`${labelPrefix} Font Family`}
            name={`${panel}.${fieldNamePrefix}FontFamily`}
          >
            {MOBILE_APP_FONTS.map(({ key, value }) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </Field>
        </Grid>
      </Grid>
      <Grid className={classes.gridItemWithPadding} container justify="space-between">
        <Grid className={classes.subGridItem} item xs={6}>
          <Field
            component={Input}
            select
            label={`${labelPrefix} Font Weight`}
            name={`${panel}.${fieldNamePrefix}FontWeight`}
          >
            <MenuItem value="100">100</MenuItem>
            <MenuItem value="200">200</MenuItem>
            <MenuItem value="300">300</MenuItem>
            <MenuItem value="400">400</MenuItem>
            <MenuItem value="500">500</MenuItem>
            <MenuItem value="600">600</MenuItem>
            <MenuItem value="700">700</MenuItem>
            <MenuItem value="800">800</MenuItem>
            <MenuItem value="900">900</MenuItem>
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            component={Input}
            select
            label={`${labelPrefix} Position`}
            name={`${panel}.${fieldNamePrefix}Position`}
          >
            <MenuItem value="left">Left</MenuItem>
            <MenuItem value="center">Center</MenuItem>
            <MenuItem value="right">Right</MenuItem>
          </Field>
        </Grid>
      </Grid>
    </>
  )
}

const ButtonSubForm = ({ panel }) => {
  const classes = usePanelStyles()

  return (
    <>
      <Grid className={classes.gridItemWithPadding} item>
        <Field component={Input} label="Button Text" name={`${panel}.buttonText`} />
      </Grid>
      <ColorPickerField panel={panel} label="Button Background Color" fieldName="buttonBackgroundColor" />
      <Grid className={classes.gridItemWithPadding} container justify="space-between">
        <Grid className={classes.subGridItem} item xs={6}>
          <Field component={Input} select label="Button Font Family" name={`${panel}.buttonFontFamily`}>
            {MOBILE_APP_FONTS.map(({ key, value }) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field component={Input} select label="Button Position" name={`${panel}.buttonPosition`}>
            <MenuItem value="left">Left</MenuItem>
            <MenuItem value="center">Center</MenuItem>
            <MenuItem value="right">Right</MenuItem>
          </Field>
        </Grid>
      </Grid>
      <ColorPickerField panel={panel} label="Button Font Color" fieldName="buttonFontColor" />
      <Grid className={classes.gridItemWithPadding} container justify="space-between">
        <Grid className={classes.subGridItem} item xs={6}>
          <Field
            component={Input}
            label="Button Font Size"
            InputProps={{
              endAdornment: <InputAdornment position="end">pixels</InputAdornment>,
            }}
            name={`${panel}.buttonFontSize`}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={Input}
            label="Button Border Radius"
            InputProps={{
              endAdornment: <InputAdornment position="end">pixels</InputAdornment>,
            }}
            name={`${panel}.buttonBorderRadius`}
          />
        </Grid>
      </Grid>
      <Grid className={classes.gridItemWithPadding} container justify="space-between">
        <Grid className={classes.subGridItem} item xs={6}>
          <Field
            component={Input}
            label="Button Vertical Padding"
            InputProps={{
              endAdornment: <InputAdornment position="end">pixels</InputAdornment>,
            }}
            name={`${panel}.buttonVerticalPadding`}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={Input}
            label="Button Horizontal Padding"
            InputProps={{
              endAdornment: <InputAdornment position="end">pixels</InputAdornment>,
            }}
            name={`${panel}.buttonHorizontalPadding`}
          />
        </Grid>
      </Grid>
    </>
  )
}

const TintSubForm = ({ panel }) => {
  const classes = usePanelStyles()

  return (
    <>
      <ColorPickerField panel={panel} label="Tint Color Overlay" fieldName="tintColorOverlay" />
      <Grid className={classes.gridItemWithPadding} item>
        <Field
          component={Input}
          type="number"
          step="0.01"
          min="0"
          max="1"
          label="Tint Color Opacity"
          name={`${panel}.tintColorOpacity`}
        />
      </Grid>
    </>
  )
}

export default VideoPanelSubForm
