import { createAction, handleActions } from 'redux-actions'

export const displayLoader = createAction('LOADER_DISPLAY_LOADER')
export const hideLoader = createAction('LOADER_HIDE_LOADER')

export const INITIAL_STATE = {
  isVisible: false,
}

export const loaderReducer = handleActions(
  {
    [displayLoader]: () => ({
      isVisible: true,
    }),
    [hideLoader]: () => ({
      isVisible: false,
    }),
  },
  INITIAL_STATE
)
